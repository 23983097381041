<template>
    <history-modal v-if='isPremium' :active="displayed" :toggleModal="toggleModal" :pieceOfContent="pieceOfContent"></history-modal>
    <div class="card has-text-white">
        <div class="card-title"> </div>
        <div class="card-image" :style="getColor(pieceOfContent['displayProperties']['rarity'])">
          <a :href="'https://personaexplorer.net/content/' + pieceOfContent.id">
            <figure class="image is-1by1">
                <img v-lazy="pieceOfContent['thumbnail']" :alt="pieceOfContent['title']['neutral'] + 'thumbnail'">
            </figure>
          </a>
        </div>

        <div class="card-content">
          <div class="media-content">
              <p class="title is-6 has-text-white">{{pieceOfContent['title']['neutral']}}</p>
              <p class="subtitle is-7">
                <a v-if='isPremium && clickable' @click="setFilter('creator', pieceOfContent['displayProperties']['creatorName'])">{{pieceOfContent['displayProperties']['creatorName']}}</a>
                <a v-else-if='isPremium && !clickable' @click="navigateTo('creator', 'released', pieceOfContent['displayProperties']['creatorName'])">{{pieceOfContent['displayProperties']['creatorName']}}</a>
                <span v-else>{{pieceOfContent['displayProperties']['creatorName']}}</span></p>
          </div>
          <br>
          <div class="content">
              <b>Price: </b>
              <a v-if="isPremium && clickable" @click="setFilter('price', pieceOfContent['displayProperties'].price)" class="has-text-right">{{pieceOfContent['displayProperties'].price}} </a>
              <a v-else-if='isPremium && !clickable' @click="navigateTo('price', 'released', pieceOfContent['displayProperties'].price)" class="has-text-right">{{pieceOfContent['displayProperties'].price}}</a>
              <span v-else class="has-text-right">{{pieceOfContent['displayProperties'].price}} </span>
              <br>

              <b>Rarity: </b>
              <a v-if="isPremium && clickable" @click="setFilter('rarity', pieceOfContent['displayProperties']['rarity'])" class="has-text-right">{{pieceOfContent['displayProperties']['rarity']}}</a>
              <a v-else-if='isPremium && !clickable' @click="navigateTo('rarity', 'released', pieceOfContent['displayProperties']['rarity'])" class="has-text-right">{{pieceOfContent['displayProperties']['rarity']}}</a>
              <span v-else class="has-text-right">{{pieceOfContent['displayProperties']['rarity']}}</span>
              <br>

              <b>Type: </b>
              <a v-if="isPremium && clickable" @click="setFilter('type', pieceOfContent['displayProperties']['pieceType'])" class="has-text-right">{{ convertedNames[pieceOfContent['displayProperties']['pieceType']] }}</a>
              <a v-else-if='isPremium && !clickable' @click="navigateTo('type', 'released', pieceOfContent['displayProperties']['pieceType'])" class="has-text-right">{{ convertedNames[pieceOfContent['displayProperties']['pieceType']] }}</a>
              <span v-else class="has-text-right" >{{ convertedNames[pieceOfContent['displayProperties']['pieceType']] }}</span>
              <br>

              <b>Purchasable: </b>
              <a v-if="isPremium && clickable && pieceOfContent['displayProperties']['purchasable']" @click="setFilter('purchasable', pieceOfContent['displayProperties']['purchasable'])" class="has-text-right" ><i class="fas fa-check"></i></a>
              <a v-if='isPremium && clickable && !pieceOfContent["displayProperties"]["purchasable"]' @click="setFilter('purchasable', pieceOfContent['displayProperties']['purchasable'])" class="has-text-right" ><i class="fas fa-times"></i></a>
              <a v-else-if='isPremium && !clickable && pieceOfContent["displayProperties"]["purchasable"]' @click="navigateTo('purchasable', 'released', pieceOfContent['displayProperties']['purchasable'])" class="has-text-right" ><i class="fas fa-check"></i></a>
              <a v-else-if='isPremium && !clickable && !pieceOfContent["displayProperties"]["purchasable"]' @click="setFilter('purchasable', 'released', pieceOfContent['displayProperties']['purchasable'])" class="has-text-right" ><i class="fas fa-times"></i></a>
              <span v-if='!isPremium && !pieceOfContent["displayProperties"]["purchasable"]' class="has-text-right" ><i class="fas fa-times"></i></span>
              <span v-if='!isPremium && pieceOfContent["displayProperties"]["purchasable"]' class="has-text-right" ><i class="fas fa-check"></i></span>
              <br>

              <b>Published: </b>
              <a v-if="isPremium && clickable" @click="setFilter('released', pieceOfContent['monthCreated'])" class="has-text-right" >{{pieceOfContent['monthCreated'].split(" ")[0].substring(0,3) + " " + pieceOfContent['monthCreated'].split(" ")[1].substring(2,4)}}</a>
              <a v-else-if='isPremium && !clickable' @click="navigateTo('released', 'released', pieceOfContent['monthCreated'])" class="has-text-right">{{pieceOfContent['monthCreated'].split(" ")[0].substring(0,3) + " " + pieceOfContent['monthCreated'].split(" ")[1].substring(2,4)}}</a>
              <span v-else class="has-text-right" >{{pieceOfContent['monthCreated'].split(" ")[0].substring(0,3) + " " + pieceOfContent['monthCreated'].split(" ")[1].substring(2,4)}}</span>
              <br>

              <b>Run for: </b>
              <a v-if="isPremium && clickable" @click="setFilter('stayed', pieceOfContent['stayedFor'])" class="has-text-right">{{pieceOfContent['stayedFor']}} month(s)</a>
              <a v-else-if='isPremium && !clickable' @click="navigateTo('stayed', 'released', pieceOfContent['stayedFor'])" class="has-text-right">{{pieceOfContent['stayedFor']}} month(s)</a>
              <span v-else class="has-text-right">
                {{pieceOfContent['stayedFor']}} month(s)
              </span>
              <span v-if='!isPremium' class="has-text-right">
                <!-- <span v-if='!isPremium' data-tooltip="For how long has this item been purchasable on the market?" class="icon is-small is-left has-tooltip-arrow"> -->
                <span v-if='!isPremium' data-tooltip="Subscribe a Premium Access to see this data." class="icon is-small is-left has-tooltip-arrow">
                  <i class="fas fa-lock lock-icon"></i>
                </span> month
              </span>
              <br>

              <b v-if="revenueMode">Revenue: </b>
              <span v-if="revenueMode" >{{pieceOfContent['revenue'] + " (" + pieceOfContent['ratio'] + ")"}}</span>
              <br v-if="revenueMode">

              <b v-if="revenueMode">Count Published: </b>
              <span v-if="revenueMode" class="has-text-right" >{{pieceOfContent['timesPublished']}}</span>
              <br v-if="revenueMode">
              <br v-if="revenueMode"><br>

              <div v-if='isPremium && clickable' class="buttons are-small">
                <button @click="setFilter('tags', tag)" type="button" class="button button-tag button-tag-extra" v-for="tag in pieceOfContent.extraTags" :key="tag">
                    <span >{{tag}}</span>
                </button>
                <button @click="setFilter('tags', tag)" type="button" class="button button-tag" v-for="tag in pieceOfContent.tags" :key="tag">
                    <span>{{tag}}</span>
                </button>
              </div>
              <div v-else-if='isPremium && !clickable' class="buttons are-small">
              <button @click="navigateTo('tags', 'released', tag)" type="button" class="button button-tag button-tag-extra" v-for="tag in pieceOfContent.extraTags" :key="tag">
                <span >{{tag}}</span>
              </button>
              <button @click="navigateTo('tags', 'released', tag)" type="button" class="button button-tag" v-for="tag in pieceOfContent.tags" :key="tag">
                <span>{{tag}}</span>
              </button>
            </div>
              
              <!-- <span class="icon is-left has-tooltip-arrow has-tooltip-multiline displayBlock" data-tooltip="Tags & Extra Tags (featured, extended, removed, dragon, car..)" v-else> -->
              <span class="icon is-left has-tooltip-arrow has-tooltip-multiline displayBlock" data-tooltip="Subscribe a Premium Access to see this data." v-else>
                  <i class="fas fa-lock lock-icon"></i>
              </span>
              
          </div>
        </div>

        <footer v-if='isPremium' class="card-footer">
            <div v-on:click="toggleModal" class="card-footer-item is-info button is-outlined"><i style="margin-right: 3px;" class="fas fa-history"></i>History</div>

            <div class="card-footer-item is-info button is-outlined">
              <a v-bind:href="'https://minecraft.net/en-us/marketplace/pdp?id=' + pieceOfContent.id" target="_blank" class="is-fullwidth"><i class="fas fa-external-link-square-alt"></i> Market</a>
            </div>
        </footer>
        <footer v-else class="card-footer">
            <div class="card-footer-item is-info button is-outlined">
             <!--  <span v-if='!isPremium' class="icon is-small is-left has-tooltip-arrow has-tooltip-multiline card-footer-item is-fullwidth" data-tooltip="Key events of this piece on the marketplace (extended, featured & in what category, delisted, relisted, etc)."> -->
              <span v-if='!isPremium' class="icon is-small is-left has-tooltip-arrow has-tooltip-multiline card-footer-item is-fullwidth" data-tooltip="Subscribe a Premium Access to see this data.">
                    <i style="margin-right: 3px;" class="fas fa-lock lock-icon"></i>History
              </span>
            </div>

          <div class="card-footer-item is-info button is-outlined">
            <a v-bind:href="'https://minecraft.net/en-us/marketplace/pdp?id=' + pieceOfContent.id" target="_blank" class="is-fullwidth"><i class="fas fa-external-link-square-alt footer-item"></i> Market</a>
          </div>
        </footer>
    </div>
</template>

<script>
import HistoryModal from './HistoryModal.vue'

const conversionList = {
  "persona_top": "Top",
  "persona_back": "Back",
  "persona_bottom": "Bottom",
  "persona_outerwear": "Outerwear",
  "persona_gloves": "Gloves",
  "persona_face_accessory": "Face Acces",
  "persona_back_accessory": "Back Acces",
  "persona_hand": "Hand",
  "persona_head": "Headwear",
  "persona_facial_hair": "Facial Hair",
  "persona_emote": "Emote",
  "persona_feet": "Feet",
  "persona_mouth": "Mouth",
  "persona_legs": "Legs",
  "persona_arms": "Arms",
  "persona_hood": "Hood",
  "persona_dress": "Dress",
  "persona_high_pants": "High Pants",
  "persona_hair": "Hair",
  "persona_skin": "Skin",
  "persona_eyes": "Eyes",
  "persona_capes": "Capes"
};

export default {
  name: 'PersonaItemCard',
  props: {
    pieceOfContent: Object,
    isPremium: Boolean,
    clickable: Boolean,
    revenueMode: Boolean
  },
  emits: ["setFilter"],
  data() {
    //default config
    return {
      convertedNames: conversionList,
      displayed: false
    }
  },
  components: {
    HistoryModal
  },
  methods: {
    navigateTo(filterBy, orderBy, data){
      this.$router.push({path: '/products/1', query: {search: "",
          filterBy: filterBy, orderBy: orderBy, filterValue: data}})
    },
    toggleModal: function() {
      this.displayed = !this.displayed;
    },
    setFilter(eventName, eventData){
      this.$emit('setFilter', [eventName, eventData])
    },
    getColor(rarity){
      switch (rarity){
        case "Common":
          return "background: linear-gradient(#232122, #6e6e6e);"
        case "Uncommon":
          return "background: linear-gradient(#1a3420, #214029);"
        case "Rare":
          return "background: linear-gradient(#0a193d, #1a409b);"
        case "Epic":
          return "background: linear-gradient(#1d0e20, #AA3CC6);"
        case "Legendary":
          return "background: linear-gradient(#2B250B, #ab7b56);"
      }
    }
  }
}
</script>

<style scoped>
@media (hover: hover) {
  .card:hover{
    transform: scale(1.05);
  }
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.media-content{
  margin-bottom: 10px;
}

.card-footer{
  margin-top: auto;
  border-top: 0;
}

.button-tag {
  background-color: #2c333b;
  border-color: transparent !important;
  font-size: .75rem!important;
  height: 1.5rem!important;
  padding: .5rem!important;
}

.button-tag:hover {
  background-color: #21262c;
}

.button-tag-extra {
  background-color: #19395f !important;
}

.button-tag-extra:hover {
  background-color: #0d1f31 !important;
}

.card-footer-item{ 
    font-size: 1rem !important;
    height: 2rem !important;
    padding:.25rem !important;
    border: none !important;
    color: #7D98FF !important;
    background-color: #1d1e23 !important;
    margin: 0.2rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.card-footer-item::-webkit-scrollbar {
  display: none;
}

.card-footer-item:hover{
  color: #363636 !important;
}

.content {
  text-align: left;
  font-size: 0.8em;
}

.card-content {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fa-lock {
  color:#9ca3af;
}

.has-tooltip-arrow {
  border-bottom: 0 !important;
}

.buttons {
  margin-top: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.buttons::-webkit-scrollbar {
  display: none;
}

.displayBlock {
  display: block !important;
}

.lock-icon {
  color: #7D98FF;
}
</style>