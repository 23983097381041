<template xmlns:cursor="http://www.w3.org/1999/xhtml">
  <div v-bind="$attrs" class="container is-max-widescreen">
    <div class="columns is-multiline is-centered is-marginless">
      <nav class="breadcrumb has-dot-separator column is-full" aria-label="breadcrumbs">
        <ul>
          <li class=""><a v-on:click="navigateTo('')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-home" aria-hidden="true"></i>
            </span>
            Home
          </a></li>
          <li><a href="#">
            <span class="icon is-small">
              <i class="fas fa-list" aria-hidden="true"></i>
            </span>
            Products
          </a></li>
          <li class=""><a v-on:click="navigateTo('featured')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-fire" aria-hidden="true"></i>
            </span>
            Featured
          </a></li>
          <li class=""><a v-on:click="navigateTo('stats')" aria-current="page">
            <span class="icon is-small">
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </span>
            Stats
          </a></li>
          <li class=""><a v-on:click="navigateTo('comparator')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-balance-scale-left" aria-hidden="true"></i>
            </span>
            Comparator
          </a></li>
          <li class=""><a v-bind:href="'https://patreon.com/PersonaExplorer'" target="_blank" aria-current="page">
              <span class="icon is-small">
                  <i class="fas fa-star"></i>
              </span>
            Premium Subscription
          </a></li>
        </ul>
      </nav>
        <div class="columns is-multiline is-centered is-marginless">
            <div class="column is-full has-text-white file">
                <label class="file-label">
                    <input class="file-input" type="file" name="resume" @change="loadAndParseCSV" accept=".csv">
                    <span class="file-cta">
                    <span class="file-icon">
                        <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">
                        Please upload PowerBI Data (.csv)
                    </span>
                    </span>
                </label>
            </div>
        </div>

            <div style="width: 100%" v-for="data in jsonToRender" :key="data">
                <h3 class="has-text-white has-text-weight-bold is-italic" style="margin-bottom: 10px;">
                {{ data.title }} </h3>
                <div class="column is-one-fifth" style="display: inline-block;" v-for="pieceData in data['pieces']" :key="pieceData">
                    <PersonaItemCard :pieceOfContent="pieceData" :isPremium=isPremiumAccount :clickable="true" :revenueMode="true"/>
                </div>
            </div>
   

        

    </div>
  </div>
  <br>
</template>

<script>
import PersonaItemCard from '../components/PersonaItemCard.vue'
//import AutoComplete from '../components/AutoComplete.vue'
import Papa from 'papaparse';


import {useMeta} from "vue-meta";

export default {
  name: 'Monitor',
  props: {
    isPremiumAccount: Boolean,
    jsonData: Object
  },
  setup () {
    useMeta({
      title: 'Monitor' ,
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  data() {
    return {
        dailyData: [],
        monthlyData: [],
        itemData: [],
        highestYearValue: 2024,
        lowestYearValue: 2021,
        jsonToRender: []
    }
  },
  components: {
    PersonaItemCard,
    //AutoComplete
  },
  created() {
    
  },
  methods: {
    loadAndParseCSV(event, info) {
      console.log(info)
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const content = e.target.result;
          this.parseCSV(content);
        };

        reader.readAsText(file);
      }
    },
    parseCSV(data) {
      Papa.parse(data, {
        header: true, // Set to false if your CSV file does not have a header row
        complete: (result) => {
          this.csvData = result.data;

          if(Object.keys(result.data[0]).length == 3){
            //it's daily data
            this.dailyData = result.data;
          }else{
            //it's monthly data
            this.monthlyData = result.data;
          }

          this.updatePage();
          
          console.log(this.csvData); // Parsed CSV data is now stored in the component's data
        },
        error: (error) => {
          console.error('Parsing error:', error);
        }
      });
    },
    toCreatorName(sourceFile){
      switch(sourceFile){
        case "data/data-gs.csv":
          return "Glorious Studios";
        case "data/data-en.csv":
          return "Endorah"
        case "data/data-ay.csv":
          return "Aymeric Pierre"
        case "data/data-bl.csv":
          return "Blocky"
      }
    },
    updatePage(){


        const itemData = [];

        //prepare the data structure
        this.monthlyData.forEach((row) => {
            if(row.partner_SRP){
                let creatorName = this.toCreatorName(row.source_file)
                if (itemData[row.product_display_name.toLowerCase() + creatorName]) {
                    itemData[row.product_display_name.toLowerCase() + creatorName].revenue += parseFloat(row.partner_SRP.slice(1));

                    if(itemData[row.product_display_name.toLowerCase() + creatorName].revenueYear[row.YearNum]){
                        itemData[row.product_display_name.toLowerCase() + creatorName].revenueYear[row.YearNum] += parseFloat(row.partner_SRP.slice(1))
                    }else{
                        itemData[row.product_display_name.toLowerCase() + creatorName].revenueYear[row.YearNum] = parseFloat(row.partner_SRP.slice(1))
                    }
                } else {
                    itemData[row.product_display_name.toLowerCase() + creatorName] = {
                        name: row.product_display_name, 
                        year: -1, 
                        revenue: 0, 
                        revenueYear: {}, 
                        uuid: -1, 
                        category: "idk", 
                        rarity: "idk", 
                        price: -1, 
                        timesPublished: 0, 
                        featuredPlus: false, 
                        featured: false, 
                        extended: false,
                        purchasable: false
                    }
                    itemData[row.product_display_name.toLowerCase() + creatorName].revenue = parseFloat(row.partner_SRP.slice(1));

                    let year = row.YearNum;

                    itemData[row.product_display_name.toLowerCase() + creatorName].revenueYear[year] = parseFloat(row.partner_SRP.slice(1))
                }
            }
        });

        let creatorList = ["Glorious Studios", "Aymeric Pierre", "Endorah", "Blocky"]

        for(let el in this.jsonData["Pieces"]){
            if(creatorList.includes(this.jsonData["Pieces"][el].displayProperties.creatorName)){

                let itemName = this.jsonData["Pieces"][el].title.neutral.toLowerCase();
                let creatorName = this.jsonData["Pieces"][el].displayProperties.creatorName;

                if(!itemData[itemName + creatorName]){
                  console.log(itemName + creatorName)
                  itemData[itemName + creatorName] = {}
                }


                itemData[itemName + creatorName].year = this.jsonData["Pieces"][el].monthCreated.split(" ")[1];
                itemData[itemName + creatorName].category = this.jsonData["Pieces"][el].displayProperties.pieceType;
                itemData[itemName + creatorName].uuid = el;

                itemData[itemName + creatorName].rarity = this.jsonData["Pieces"][el].displayProperties.rarity;
                itemData[itemName + creatorName].price = this.jsonData["Pieces"][el].displayProperties.price;
                itemData[itemName + creatorName].purchasable = this.jsonData["Pieces"][el].displayProperties.purchasable;

                //todo add category and year
                if(this.jsonData["Pieces"][el].extraTags.includes("featured")){
                    itemData[itemName + creatorName].featured = true;
                }

                if(this.jsonData["Pieces"][el].extraTags.includes("featured+")){
                    itemData[itemName + creatorName].featuredPlus = true;
                }

                if(this.jsonData["Pieces"][el].extraTags.includes("extended")){
                    itemData[itemName + creatorName].extended = true;
                }


                itemData[itemName + creatorName].timesPublished += 1;
            }
        }

        //Start compute average revenue by rarity category
        let rarityMoney = {}
        let rarityCount = {}
        for(let itemName in itemData){
            //skin pack items
            if(itemData[itemName].rarity == "idk"){
                delete itemData[itemName];
                continue
            }
            if(rarityCount[itemData[itemName].rarity]){
                rarityCount[itemData[itemName].rarity] += 1;
            }else{
                rarityCount[itemData[itemName].rarity] = 1;
            }

            if(rarityMoney[itemData[itemName].rarity]){
                rarityMoney[itemData[itemName].rarity] += itemData[itemName].revenue;
            }else{
                rarityMoney[itemData[itemName].rarity] = itemData[itemName].revenue;
            }
        }

        for(let r in rarityCount){
            console.log("Category: " + r + ", average amount: " + rarityMoney[r] / rarityCount[r])
        }

        //End compute average revenue by rarity category

        this.itemData = itemData;

        this.computeAvgRevenueByCategory()
        this.computeAvgRevenueByYear()
        this.computeTop20Items()
    },
    computeAvgRevenueByCategory() {
        let categoryRevenue = {};
        let categoryCount = {};

        for (let itemName in this.itemData) {
            let item = this.itemData[itemName];
            let category = item.category; // Assuming there's a 'category' field

            if (categoryCount[category]) {
              categoryCount[category] += 1;
            categoryRevenue[category] += item.revenue;
            } else {
            categoryCount[category] = 1;
            categoryRevenue[category] = item.revenue;
            }
        }

        let avgRevenueByCategory = {};
        for (let category in categoryRevenue) {
            avgRevenueByCategory[category] = categoryRevenue[category] / categoryCount[category];
        }

        console.log("Average Revenue by Category:", avgRevenueByCategory);
        return avgRevenueByCategory;
    },
    computeAvgRevenueByYear() {
        //todo le faire avec l'autre fichier plutot
        let yearRevenue = {};
        let yearCount = {};
        let globalRevenue = 0;

        for (let itemName in this.itemData) {
            let item = this.itemData[itemName];

            for(let y in this.itemData[itemName].revenueYear){
                if (yearCount[y]) {
                    if(item.revenueYear[y] > 10){
                        yearCount[y] += 1;
                        yearRevenue[y] += item.revenueYear[y];
                    }
                } else {
                    if(item.revenueYear[y] > 10){
                        yearCount[y] = 1;
                        yearRevenue[y] = item.revenueYear[y];
                    }
                }
            }

            globalRevenue += this.itemData[itemName].revenue;
        }

        let avgRevenueByYear = {};
        for (let year in yearRevenue) {
            console.log("Year: " + year + ", avg: " + yearRevenue[year] / yearCount[year] + ", with count of items: " + yearCount[year])
        }

        console.log("Global avg:", globalRevenue / Object.keys(this.itemData).length + ", with count of items: " + Object.keys(this.itemData).length);

        return avgRevenueByYear;
    },
    computeTop20Items() {
        let itemsWithRatio = [];

        for (let itemName in this.itemData) {
            const item = this.itemData[itemName];

            if (!item.purchasable) {
                
                const ratio = item.timesPublished > 0 ? item.revenue / item.timesPublished : 0;


                itemsWithRatio.push({
                    name: itemName,
                    ratio: ratio,
                    revenue: item.revenue,
                    timesPublished: item.timesPublished,
                    uuid: item.uuid
                });
            }
        }

        // Sort the array by the ratio of revenue to timesPublished
        itemsWithRatio.sort((a, b) => b.ratio - a.ratio);

        console.log(itemsWithRatio)

        // Slice the first 20 items from the sorted array
        const top20Items = itemsWithRatio.slice(0, 70);

        let piecesRow = []
        let tempData = []

        for(let el in top20Items){
            //console.log(top20Items[el].uuid)
            let pieceInfo = this.lookForPiece(top20Items[el].uuid)
            if (pieceInfo !== false) {
                //console.log(top20Items[el])
                pieceInfo.revenue = top20Items[el].revenue;
                pieceInfo.ratio = top20Items[el].ratio.toFixed();
                pieceInfo.timesPublished = top20Items[el].timesPublished;


                piecesRow.push(pieceInfo)
            }
        }

        tempData.push({
            title: "Top 50",
            pieces: piecesRow
        })
        this.jsonToRender = tempData;

        console.log("Top 50 Items by Revenue/Republish Ratio:", top20Items);
    },
    lookForPiece(id) {
      if (this.jsonData["Pieces"][id]) {
        return this.jsonData["Pieces"][id]
      } else {
        return false
      }
    },
  }
}
</script>

<style>
.specialrow {
  margin-top: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#fa {
  background-color: white;
  color: #1C1E24;
}

strong {
  color: #888e98 !important;
}

input[type="text"], input[type="search"] {
  background-color: #374151 !important;
  border: 0 !important;
}

.field {
  text-align: left;
}

#results {
  text-align: left !important;
}

.label {
  text-align: left;
}

.select {
  -webkit-box-align: center;
  -webkit-box-pack: start;
}
</style>
