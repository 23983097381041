<template>
    <div class="card has-text-white is-flex" style="width: 100%">
        <div class="card-title"> </div>
        <div class="card-image" style="width: 50%" :style="getColor(pieceOfContent['displayProperties']['rarity'])">
          <a :href="'minecraft:?showStoreOffer=' + pieceOfContent.id">
            <figure class="image is-1by1">
                <img v-lazy="pieceOfContent['thumbnail']" :alt="pieceOfContent['title']['neutral'] + 'thumbnail'">
            </figure>
          </a>
        </div>

        <div class="card-stacked">
          <div class="card-content">
            <div class="media-content">
                <p class="title is-3 has-text-white">{{pieceOfContent['title']['neutral']}}</p>
                <p class="subtitle is-5">
                  <a v-if='isPremium' @click="navigateTo('creator', 'released', pieceOfContent['displayProperties']['creatorName'])">{{pieceOfContent['displayProperties']['creatorName']}}</a>
                  <span v-else>{{pieceOfContent['displayProperties']['creatorName']}}</span>
                </p>
            </div>
            <br>
            <div class="content">
                <b>Price: </b>
                <a v-if='isPremium' style="text-align: right" @click="navigateTo('price', 'released', pieceOfContent['displayProperties']['price'])">{{pieceOfContent['displayProperties']['price']}}</a>
                <a v-else style="text-align: right" >{{pieceOfContent['displayProperties']['price']}} </a>
                <br>

                <b>Rarity: </b>
                <a v-if='isPremium' style="text-align: right" @click="navigateTo('rarity', 'released', pieceOfContent['displayProperties']['rarity'])">{{pieceOfContent['displayProperties']['rarity']}}</a>
                <a v-else style="text-align: right" >{{pieceOfContent['displayProperties']['rarity']}}</a>
                <br>

                <b>Piece Type: </b>
                <a v-if='isPremium' style="text-align: right" @click="navigateTo('type', 'released', pieceOfContent['displayProperties']['pieceType'])">{{convertedNames[pieceOfContent['displayProperties']['pieceType']]}}</a>
                <a v-else style="text-align: right" >{{ convertedNames[pieceOfContent['displayProperties']['pieceType']] }}</a>
                <br>

                <b>Purchasable: </b>
                <a v-if="isPremium && pieceOfContent['displayProperties']['purchasable']" style="text-align: right" @click="navigateTo('purchasable', 'released', pieceOfContent['displayProperties']['purchasable'])">✔</a>
                <a v-else-if="isPremium && !pieceOfContent['displayProperties']['purchasable']" style="text-align: right" @click="navigateTo('purchasable', 'released', pieceOfContent['displayProperties']['purchasable'])">✖</a>
                <a v-else-if="!isPremium && pieceOfContent['displayProperties']['purchasable']" style="text-align: right" >✔</a>
                <a v-else style="text-align: right" >✖</a>
                <br>

                <b>Published: </b>
                <a v-if='isPremium' style="text-align: right" @click="navigateTo('released', 'released', pieceOfContent['monthCreated'])">{{pieceOfContent['monthCreated']}}</a>
                <a v-else style="text-align: right" >{{pieceOfContent['monthCreated']}}</a>
                <br><br><br>

                <div v-if="!isPremium" class="buttons are-small">
                  <button type="button" class="button button-tag" v-for="tag in pieceOfContent.tags" :key="tag">
                      <span>{{tag}}</span>
                  </button>
                </div>
                <div v-else class="buttons are-small">
                  <button type="button" class="button button-tag" @click="navigateTo('tags', 'released', tag)" v-for="tag in pieceOfContent.tags" :key="tag">
                    <span>{{tag}}</span>
                  </button>
                </div>
                
            </div>
          </div>

          <footer class="card-footer">
            <div class="card-footer-item is-info button is-outlined">
              <a v-bind:href="'https://minecraft.net/en-us/marketplace/pdp?id=' + pieceOfContent.id" target="_blank" style="width: 100%"><i class="fas fa-external-link-square-alt"></i> Market</a>
            </div>
          </footer>
        </div>
    </div>
</template>

<script>

const conversionList = {
  "persona_top": "Top",
  "persona_back": "Back",
  "persona_bottom": "Bottom",
  "persona_outerwear": "Outerwear",
  "persona_gloves": "Gloves",
  "persona_face_accessory": "Face Acces",
  "persona_back_accessory": "Back Acces",
  "persona_hand": "Hand",
  "persona_head": "Headwear",
  "persona_facial_hair": "Facial Hair",
  "persona_emote": "Emote",
  "persona_feet": "Feet",
  "persona_mouth": "Mouth",
  "persona_legs": "Legs",
  "persona_arms": "Arms",
  "persona_hood": "Hood",
  "persona_dress": "Dress",
  "persona_high_pants": "High Pants",
  "persona_hair": "Hair",
  "persona_skin": "Skin",
  "persona_eyes": "Eyes",
  "persona_capes": "Capes"
};

export default {
  name: 'PersonaContentView',
  props: {
    pieceOfContent: Object,
    isPremium: Boolean
  },
  data() {
    return {
      convertedNames: conversionList
    }
  },
  methods: {
    navigateTo(filterBy, orderBy, data){
      this.$router.push({path: '/products/1', query: {search: "",
          filterBy: filterBy, orderBy: orderBy, filterValue: data}})
    },
    getColor(rarity){
      switch (rarity){
        case "Common":
          return "background: linear-gradient(#232122, #6e6e6e);"
        case "Uncommon":
          return "background: linear-gradient(#1a3420, #214029);"
        case "Rare":
          return "background: linear-gradient(#0a193d, #1a409b);"
        case "Epic":
          return "background: linear-gradient(#1d0e20, #AA3CC6);"
        case "Legendary":
          return "background: linear-gradient(#2B250B, #ab7b56);"
      }
    }
  }
}
</script>

<style scoped>

.card {
  display: flex;
  text-align: center !important;
}

.card-image {
  width: 100%;
  max-width: 500px;
}

.card-stacked {
  flex-direction: column;
  flex: 1 1 auto;
  display: flex;
  position: relative;
}
      
.card-content {
  flex-grow: 1;
}

.card-footer{
  margin-top: auto;
  border-top: 0;
}

.button-tag {
  background-color: #2c333b;
  border-color: transparent !important;
  font-size: .75rem!important;
  height: 1.5rem!important;
  padding: .5rem!important;
}

.button-tag:hover {
  background-color: #21262c;
}

.card-footer-item{
  font-size: 1rem !important;
  height: 2rem !important;
  padding:.25rem !important;
  border: none !important;
  color: #7D98FF !important;
  background-color: #1d1e23 !important;
  margin: 0.2rem;
}

.card-footer-item:hover{
  color: #363636 !important;
}

.content {
  text-align: left;
  font-size: 1em;
}

.buttons {
  margin-top: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap !important;
}
</style>