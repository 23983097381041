<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Persona Explorer` : `Persona Explorer` }}</template>
  </metainfo>
  <div class="globaldiv">
    <div class="header">
      <div onclick="location.href='https://twitter.com/GloriousStudios'" class="logoheader is-desktop"></div>
      <div class="textheader">
        <span class="has-text-white textTitle">Persona Explorer</span>
        <p v-if="nameUser" class="welcomeBox">
          Welcome back {{ nameUser }}!
        </p>
      </div>
      <div class="loginbutton" style="margin-left: 5px; display: inline-table" v-if="!$auth.loading.value">
        <a v-if="!$auth.isAuthenticated.value" title="Log in" @click="login" class="authbutton text has-text-weight-bold">Login</a>
        <a v-if="$auth.isAuthenticated.value" title="Log out" @click="logout" class="authbutton text has-text-weight-bold">Logout</a>
      </div>
    </div>

    <router-view class="view" :isPremiumAccount=isPremiumAccount :jsonData=jsonData style="margin-top: 50px"></router-view>

    <footer style='background-color: #1C1E24' class="footer">
      <div class="content has-text-centered">
          <strong>Persona Explorer</strong> is not affiliated or endorsed by Mojang AB. Mojang and Minecraft are trademarks of Mojang AB.
          <br>Made with &#10084;&#65039; by <a href="https://www.linkedin.com/in/arthur-bigot-59a4aa1a5/">Arthur
          Bigot</a>. &copy; Copyright 2022.
          <br>
          <a href="https://www.iubenda.com/privacy-policy/64534225" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
      </div>
    </footer>
  </div>
</template>


<script>
import json from './../personaReport.json'
import axios from "axios";
//import premiumReportJson from '../personaReportPremium.data'
//import trendReportJson from '../PersonaManifestData.data'
import CryptoJS from "crypto-js";
import {useToast} from "vue-toastification";
import { storeData, fetchData } from './db';

const jsonData = json;

export default {
  name: 'App',
  data() {
    return {
      nameUser: null,
      isPremiumAccount: false,
      jsonData: jsonData,
    }
  },
  mounted() {
    if (this.$auth.isAuthenticated.value) {
      this.getUserData()
    }else{
      const toast = useToast();
      toast("Login to access all locked features for FREE!", {
        position: "top-right",
        timeout: 8032,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.56,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    }
  },
  methods: {
    async login() {
      if (this.$route.name === "Default Page") {
        await this.$auth.loginWithRedirect();
        await this.getUserData()
      } else {
        await this.$auth.loginWithPopup();
        await this.getUserData()
      }
    },
    settings(){
      this.$router.push({path: "/settings"})
    },
    async requestPremiumData(token) {
      /*
      let dataReport = JSON.parse(CryptoJS.AES.decrypt(premiumReportJson, token).toString(CryptoJS.enc.Utf8));
      for (let index in dataReport["Pieces"]) {
        if(this.jsonData["Pieces"][index]){
          this.jsonData["Pieces"][index].extraTags = dataReport["Pieces"][index].extraTags
          this.jsonData["Pieces"][index].events = dataReport["Pieces"][index].events
          this.jsonData["Pieces"][index].stayedFor = dataReport["Pieces"][index].stayedFor
        }
      }
      this.jsonData["Stats"] = dataReport["Stats"]

      this.jsonData["FeaturedData"] = JSON.parse(CryptoJS.AES.decrypt(trendReportJson, token).toString(CryptoJS.enc.Utf8))
      this.isPremiumAccount = true
      */

      // Fetching personaData
      try {
        let personaData = await fetchData('personaData');
        let response = JSON.parse(personaData);
        response.data = JSON.parse(CryptoJS.AES.decrypt(response.data, token).toString(CryptoJS.enc.Utf8));

      

        for (let index in response.data["Pieces"]) {
          if (this.jsonData["Pieces"][index]) {
            this.jsonData["Pieces"][index].extraTags = response.data["Pieces"][index].extraTags;
            this.jsonData["Pieces"][index].events = response.data["Pieces"][index].events;
            this.jsonData["Pieces"][index].stayedFor = response.data["Pieces"][index].stayedFor;
          }
        }

        jsonData["Stats"] = response.data["Stats"]
        jsonData["Global"] = response.data["Global"]

        let trendPersonaData = await fetchData('trendPersonaData');
        response = JSON.parse(trendPersonaData);
        response.data = JSON.parse(CryptoJS.AES.decrypt(response.data, token).toString(CryptoJS.enc.Utf8));
        this.jsonData["FeaturedData"] = response.data;
        this.isPremiumAccount = true;

      } catch (error) {
        console.error('Error fetching persona data:', error);
      }
      



      return axios.get("https://miste.fr/personaData.php", {'headers': {'Authorization': this.$auth.user["__raw"]}})
          .then((response) => {
            //console.log(response.data.length)
            storeData('personaData', JSON.stringify(response))
            //.then(() => console.log('Persona data stored successfully'))
            //.catch(error => console.error('Error storing persona data:', error));

            response.data = JSON.parse(CryptoJS.AES.decrypt(response.data, token).toString(CryptoJS.enc.Utf8));
            for (let index in response.data["Pieces"]) {
              if(this.jsonData["Pieces"][index]){
                this.jsonData["Pieces"][index].extraTags = response.data["Pieces"][index].extraTags
                this.jsonData["Pieces"][index].events = response.data["Pieces"][index].events
                this.jsonData["Pieces"][index].stayedFor = response.data["Pieces"][index].stayedFor
              }
            }
            jsonData["Stats"] = response.data["Stats"]
            return axios.get("https://miste.fr/trendPersonaData.php", { 'headers': { 'Authorization': this.$auth.user["__raw"]}})
                .then((response) => {
                  // Storing trendPersonaData
                  storeData('trendPersonaData', JSON.stringify(response))
                    //.then(() => console.log('Trend persona data stored successfully'))
                    //.catch(error => console.error('Error storing trend persona data:', error));

                  response.data = JSON.parse(CryptoJS.AES.decrypt(response.data, token).toString(CryptoJS.enc.Utf8));
                  this.jsonData["FeaturedData"] = response.data
                  this.isPremiumAccount = true
            });
        });
       
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    setAccountName(data) {
      this.nameUser = data
    },
    async getUserData() {
      let data = await this.$auth.getIdTokenClaims()
      if (data !== undefined) {
        this.$auth.user = data
        this.setAccountName(this.$auth.user.name)
        this.loginData = data

        if(Array.isArray(this.$auth.user["https://personaexplorer.net/roles"]) && this.$auth.user["https://personaexplorer.net/roles"].includes("Premium")){
          await this.requestPremiumData(this.$auth.user["https://personaexplorer.net/premium_data_token"]);
        }
      }
    }
  }
}
</script>

<style>
footer {
  color: #9CA3AF;
}

strong {
  color: #888e98 !important;
}

.welcomeBox {
  color: #9ca3af;
  background-color: Transparent;
  border: none;
  font-size: 15px;
}

body {
  background-color: #1C1E24;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  background: rgba(35, 41, 48, 1);
  opacity: 1;
  border: 1px solid rgba(42, 42, 42, 1);
  text-align: center;

  max-width: 100%;
  margin: 0 auto;
}

.logoheader{
  float: left;
  width: 44px;
  height: 44px;
  background: url("assets/logo.webp");
  background-size: cover;
  cursor: pointer;
  margin-left: 10px;
}

@media only screen and (max-width: 500px) {
  .logoheader{
    display: none;
  }
}

.loginbutton {
  margin-right: 10px;
  float: right;
}

.textheader {
  display: inline-block;
  margin: 0 auto;
}

.textTitle {
  color: rgba(255, 255, 255, 0.800000011920929);
  font-weight: 700;
  font-size: 30px;
}

.authbutton {
  color: #7D98FF;
  text-align: center;
  vertical-align: middle;
  line-height: 90px;
  font-size: 1.30em;
}

span {
  color: #7D98FF;
}
</style>