import { createApp } from 'vue'
import { vue3Debounce } from 'vue-debounce'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGtag from "vue-gtag-next";
import { createMetaManager } from 'vue-meta'
import Toast from "vue-toastification";

import authConfigProd from '../auth_config.json';
import authConfigDev from '../dev_auth_config.json';

import { setupAuth } from './auth'
import VueLazyLoad from 'vue3-lazyload'

library.add(fas)

import "./assets/main.scss"
import '../node_modules/nprogress/nprogress.css'

let app = createApp(App)
.component('fa', FontAwesomeIcon)
.use(VueGtag, {
    property: {
      id: "G-FXXPDV9FJ9"
    }
})
.use(router)
    .use(VueLazyLoad)
    .use(Toast)
    .use(createMetaManager())
    .directive('debounce', vue3Debounce({ lock: true}))

function callbackRedirect(appState) {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  );
}

let authConfigPath;

if (process.env.NODE_ENV === 'production') {
  authConfigPath = authConfigProd;
} else {
  authConfigPath = authConfigDev;
}


setupAuth(authConfigPath, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app')
});