<template xmlns:cursor="http://www.w3.org/1999/xhtml">
  <div v-bind="$attrs" class="container is-max-widescreen">
    <nav class="breadcrumb has-dot-separator column is-full" aria-label="breadcrumbs">
      <ul>
        <li class=""><a v-on:click="navigateTo('')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-home" aria-hidden="true"></i>
            </span>
            Home
          </a></li>
        <li><a v-on:click="navigateTo('products')">
            <span class="icon is-small">
              <i class="fas fa-list" aria-hidden="true"></i>
            </span>
            Products
          </a></li>
        <li class=""><a href="" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-fire" aria-hidden="true"></i>
            </span>
            Featured
          </a></li>
        <li class=""><a v-on:click="navigateTo('stats')" aria-current="page">
            <span class="icon is-small">
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </span>
            Stats
          </a></li>
        <li class=""><a v-on:click="navigateTo('comparator')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-balance-scale-left" aria-hidden="true"></i>
            </span>
            Comparator
          </a></li>
        <li class=""><a v-bind:href="'https://patreon.com/PersonaExplorer'" target="_blank" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-star"></i>
            </span>
            Premium Subscription
          </a></li>
      </ul>
    </nav>

    <div class="column is-full has-text-centered">
      <h5 class="has-text-white is-italic">Every Tuesday, the different sections of the
        Dressing Room are updated with new items.<br> You will find on this page the history
        of these sections, either by week, or by section name.
      </h5>
    </div>


    <div v-if="$auth.isAuthenticated.value && isPremiumAccount" class="columns is-multiline is-centered is-marginless">
      <div class="column is-two-fifths">
        <label class="label has-text-white">Search</label>
        <div class="field">
          <p class="control is-expanded has-icons -right has-text-white">
            <input class="input has-text-white" type="search" placeholder="Enter Item Name..."
              v-debounce:500ms="searchHandler" v-model="searchTerm" />
          </p>
        </div>
      </div>
      <div class="column is-one-fifth">
        <label class="label has-text-white">Filter Data By</label>
        <span class="select selector">
          <select class="custom-select" @change="onChange($event)" v-model="filterMode">
            <option cursor:pointer value="Period">Time Period</option>
            <option cursor:pointer value="Category">Section</option>
          </select>
        </span>
      </div>

      <div v-if="filterMode === 'Period'" class="column is-two-fifths">
        <label class="label has-text-white">Time Period</label>
        <span class="select selector">
          <select id="select" class="custom-select" @change="onChange($event)" v-model="dateDataSelected">
            <option cursor:pointer v-for="option in dateData" :key="option">
              {{ option }}
            </option>
          </select>
        </span>
      </div>

      <div v-else class="column is-two-fifths">
        <label class="label has-text-white">Section</label>
        <span class="select selector">
          <select class="custom-select" @change="onChange($event)" v-model="categorySelected">
            <option cursor:pointer value="DressingRoomFeaturedQuery">Dressing Room & Profile (without first party items)
            </option>
            <option cursor:pointer value="Top">Top</option>
            <option cursor:pointer value="Bottom">Bottom</option>
            <option cursor:pointer value="Outerwear">Outerwear</option>
            <option cursor:pointer value="Headwear">Headwear</option>
            <option cursor:pointer value="Gloves">Gloves</option>
            <option cursor:pointer value="Feet">Feet</option>
            <option cursor:pointer value="Face Accessory">Face Accessory</option>
            <option cursor:pointer value="Back Accessory">Back Accessory</option>
            <option cursor:pointer value="Profile Featured">Profile</option>
          </select>
        </span>
      </div>

      <div class="column is-full has-text-white">
        <br>
      </div>

      <div class="colomn" style="width: 100%" v-for="data in jsonToRender" :key="data">
        <h3 class="has-text-white has-text-weight-bold is-italic" style="margin-bottom: 10px;">
          {{ data.title }} </h3>
        <div class="columns specialrow" style="margin-bottom: 30px;">
          <div class="column is-2" v-for="pieceData in data['pieces']" :key="pieceData">
            <PersonaItemCard :pieceOfContent="pieceData" :isPremium=isPremiumAccount :clickable="false" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="$auth.isAuthenticated.value && !isPremiumAccount"
      class="column-error column is-full has-text-centered">
      <p class="has-text-white">You need a Premium subscription to this this page.</p>
      <p class="has-text-white">Subscribe here: <a href="https://patreon.com/PersonaExplorer"
          target="_blank">https://patreon.com/PersonaExplorer</a></p>
    </div>
    <div v-else class="column-error column is-full has-text-centered">
      <!-- <p class="has-text-white">Please login to see this page (access restricted to <strong>Premium</strong> users only). </p> -->
      <p class="has-text-white">Please login to see this page. </p>
    </div>
  </div>
</template>

<script>
import PersonaItemCard from '../components/PersonaItemCard.vue'
import NProgress from "nprogress";
import { useMeta } from "vue-meta";

export default {
  name: 'FeaturedView',
  props: {
    isPremiumAccount: Boolean,
    jsonData: Object
  },
  setup() {
    useMeta({
      title: 'Featured',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  data() {
    return {
      categorySelected: "DressingRoomFeaturedQuery",
      dateDataSelected: "",
      dateData: [],
      filterMode: "Period",
      jsonToRender: [],
      pathToData: 0,
      searchTerm: "",
      lastSearchTerm: ""
    }
  },
  components: {
    PersonaItemCard
  },
  mounted() {
    this.updatePage()
  },
  watch: {
    isPremiumAccount: function (newVal) {
      if (newVal) {
        this.updatePage()
      }
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push({ path: "/" + path })
    },
    onChange() {
      this.updatePage()
    },
    searchHandler(value) {
      this.lastSearchTerm = value
      this.updatePage()
    },
    lookForPiece(id) {
      if (this.jsonData["Pieces"][id]) {
        return this.jsonData["Pieces"][id]
      } else {
        return false
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getNextTuesdayAfterDate(d) {
      let days = 0;
      let tempDate = new Date(d);
      if (tempDate.getDay() <= 2) {
        days = 2 - tempDate.getDay();
      } else {
        days = 7 - tempDate.getDay() + 2;
      }
      return new Date(tempDate.setDate(tempDate.getDate() + days));
    },
    updatePage() {
      if (this.isPremiumAccount) {
        NProgress.start()
        let tempData = [];

        if (this.filterMode === "Period") {
          if (this.dateDataSelected === "") {
            let dateData = [];
            let lastDate = null;
            let data = this.jsonData["FeaturedData"]["results"];


            for (let i in (data.reverse())) {
              let dateItem = this.jsonData["FeaturedData"]["results"][i]["creationDate"];
              let dateFormatted = new Date(dateItem)
              if (dateFormatted.getYear() > 120) {
                if (this.getNextTuesdayAfterDate(dateFormatted).getDate() < dateFormatted.getDate()) {
                  dateFormatted = this.getNextTuesdayAfterDate(dateFormatted);
                  dateFormatted.setDate(1);
                } else {
                  dateFormatted = this.getNextTuesdayAfterDate(dateFormatted);
                }

                let dateString;
                dateString = dateFormatted.toDateString() + " - "

                if (lastDate !== null) {
                  console.log("lastDate not null")
                  console.log(lastDate)
                  if ((new Date(lastDate)).getDate() !== (new Date(dateFormatted)).getDate()) {
                    dateString += lastDate.toDateString() + "(" + this.jsonData["FeaturedData"]["results"][i].id + ")";
                    dateData.push(dateString)
                  }
                } else {

                  if ((new Date(dateFormatted.setDate(dateFormatted.getDate() + 7))).getDate()
                    < new Date(dateFormatted.setDate(dateFormatted.getDate() - 7)).getDate()) {
                    dateString += new Date(dateFormatted.setDate(31)).toDateString();
                  } else {
                    dateFormatted.setDate(dateFormatted.getDate() + 1)
                    dateString += this.getNextTuesdayAfterDate(dateFormatted).toDateString();
                    dateFormatted.setDate(dateFormatted.getDate() - 1)
                  }
                  dateString += "(" + this.jsonData["FeaturedData"]["results"][i].id + ")"
                  dateData.push(dateString)
                }

                lastDate = dateFormatted;
              }
            }

            this.dateData = dateData.reverse();
            this.dateDataSelected = dateData.reverse()[0];
            return this.updatePage()
          }

          let categoryData = [
            "Top", "Bottom", "Outerwear", "Headwear", "Gloves", "Feet", "Face Accessory", "Back Accessory"
          ];

          let selectedId = this.dateDataSelected.split("(")[1].split(")")[0];

          for (let result of this.jsonData["FeaturedData"]["results"]) {
            let itemId = result.id;


            if (itemId === selectedId) {

              let piecesRow = [];
              if (result.displayProperties?.dressingRoomLayout?.profileFeaturedQuery) {
                const tagTypes = ["orTags", "andTags", "productIds"];

                for (let tagType of tagTypes) {
                  if (result.displayProperties.dressingRoomLayout.profileFeaturedQuery[tagType]) {
                    for (let tagItem of result.displayProperties.dressingRoomLayout.profileFeaturedQuery[tagType]) {
                      let pieceInfo = this.lookForPiece(tagItem);

                      if (pieceInfo !== false) {
                        if (this.searchTerm !== "" && pieceInfo.title.neutral.toLowerCase().includes(this.searchTerm.toLowerCase())) {
                          piecesRow.push(pieceInfo);
                        } else if (this.searchTerm === "") {
                          piecesRow.push(pieceInfo);
                        }
                      }
                    }
                  }
                }

                if (piecesRow.length > 0) {
                  tempData.push({
                    title: "Profile",
                    pieces: piecesRow,
                    date: new Date(result.creationDate)
                  });
                }
              }
              
              piecesRow = [];
              if (result.displayProperties?.dressingRoomLayout?.dressingRoomFeaturedQuery) {
                const tagTypes = ["orTags", "andTags", "productIds"];

                for (let tagType of tagTypes) {
                  if (result.displayProperties.dressingRoomLayout.dressingRoomFeaturedQuery[tagType]) {
                    for (let tagItem of result.displayProperties.dressingRoomLayout.dressingRoomFeaturedQuery[tagType]) {
                      let pieceInfo = this.lookForPiece(tagItem);

                      if (pieceInfo !== false) {
                        if (this.searchTerm !== "" && pieceInfo.title.neutral.toLowerCase().includes(this.searchTerm.toLowerCase())) {
                          piecesRow.push(pieceInfo);
                        } else if (this.searchTerm === "") {
                          piecesRow.push(pieceInfo);
                        }
                      }
                    }
                  }
                }

                if (piecesRow.length > 0) {
                  tempData.push({
                    title: "Dresssing Room",
                    pieces: piecesRow,
                    date: new Date(result.creationDate)
                  });
                }
              }

              break;
            }
          }

          // Iterate over categories for additional featured queries
          for (let result of this.jsonData["FeaturedData"]["results"]) {
            let itemId = result.id;
            if (itemId === selectedId) {
              for (let i = 0; i < categoryData.length; i++) {
                let piecesRow = [];
                const categoryIndices = [0, 1]; // Assuming indexes to look up sub-categories
                let subCatTitleFinal = ""
                for (let categoryIndex of categoryIndices) {
                  const subCategory = result.displayProperties?.dressingRoomLayout?.categoryObjects?.[categoryIndex]?.subCategories?.[i];
                  let subCatTitle = subCategory.title.split(".")[2]
                  subCatTitle = subCatTitle.charAt(0).toUpperCase() + subCatTitle.slice(1);

        
                  if (subCategory?.featuredQuery) {
                    const tagTypes = ["orTags", "andTags", "productIds"];

                    for (let tagType of tagTypes) {
                      if (subCategory.featuredQuery[tagType]) {
                        for (let tagItem of subCategory.featuredQuery[tagType]) {

                          subCatTitleFinal = subCatTitle
                          let pieceInfo = this.lookForPiece(tagItem);

                          if (pieceInfo !== false) {
                            if (this.searchTerm !== "" && pieceInfo.title.neutral.toLowerCase().includes(this.searchTerm.toLowerCase())) {
                              piecesRow.push(pieceInfo);
                            } else if (this.searchTerm === "") {
                              piecesRow.push(pieceInfo);
                            }
                          }
                        }
                      }
                    }
                  }
                }

                if (piecesRow.length > 0) {
                  tempData.push({
                    title: subCatTitleFinal,
                    pieces: piecesRow,
                    date: new Date(result.creationDate)
                  });
                }
              }
              break;
            }
          }
        } else {
          if (this.categorySelected === "dressingRoomFeaturedQuery" || this.categorySelected === "DressingRoomFeaturedQuery") {
            this.pathToData = 0
          } else if (this.categorySelected === "Top") {
            this.pathToData = 0
          } else if (this.categorySelected === "Bottom") {
            this.pathToData = 1
          } else if (this.categorySelected === "Outerwear") {
            this.pathToData = 2
          } else if (this.categorySelected === "Headwear") {
            this.pathToData = 3
          } else if (this.categorySelected === "Gloves") {
            this.pathToData = 4
          } else if (this.categorySelected === "Feet") {
            this.pathToData = 5
          } else if (this.categorySelected === "Face Accessory") {
            this.pathToData = 6
          } else if (this.categorySelected === "Back Accessory") {
            this.pathToData = 7
          } else if (this.categorySelected === "Profile Featured") {
            this.pathToData = 1
          }

          if (this.categorySelected === "dressingRoomFeaturedQuery" || this.categorySelected === "DressingRoomFeaturedQuery") {
            let lastDate = null;
            for (let i in this.jsonData["FeaturedData"]["results"]) {

              let dateItem = this.jsonData["FeaturedData"]["results"][i]["creationDate"];

              let dateFormatted = new Date(dateItem)
              if (dateFormatted.getYear() > 120) {
                let piecesRow = []
                if (this.getNextTuesdayAfterDate(dateFormatted).getDate() < dateFormatted.getDate()) {
                  dateFormatted = this.getNextTuesdayAfterDate(dateFormatted);
                  dateFormatted.setDate(1);
                } else {
                  dateFormatted = this.getNextTuesdayAfterDate(dateFormatted);
                }

                const queryTypes = ["profileFeaturedQuery", "dressingRoomFeaturedQuery"];
                const tagTypes = ["productIds", "orTags", "andTags"];

                for (let queryType of queryTypes) {
                  if (this.jsonData["FeaturedData"]["results"][i]["displayProperties"]["dressingRoomLayout"][queryType]) {
                    for (let tagType of tagTypes) {
                      for (let i2 in this.jsonData["FeaturedData"]["results"][i]["displayProperties"]["dressingRoomLayout"][queryType][tagType]) {
                        let pieceInfo = this.lookForPiece(this.jsonData["FeaturedData"]["results"][i]["displayProperties"]["dressingRoomLayout"][queryType][tagType][i2]);

                        if (pieceInfo !== false && pieceInfo.displayProperties["creatorName"] !== "Minecraft" && !pieceInfo.extraTags.includes("first_party")) {
                          if (this.searchTerm !== "") {
                            if (pieceInfo.title["neutral"].toLowerCase().includes(this.searchTerm.toLowerCase())) {
                              piecesRow.push(pieceInfo);
                            }
                          } else {
                            piecesRow.push(pieceInfo);
                          }
                        }
                      }
                    }
                  }
                }


                let dateString;
                dateString = dateFormatted.toDateString() + " - "
                if (lastDate !== null) {
                  dateString += lastDate.toDateString();
                } else {
                  if ((new Date(dateFormatted.setDate(dateFormatted.getDate() + 7))).getDate()
                    < new Date(dateFormatted.setDate(dateFormatted.getDate() - 7)).getDate()) {
                    dateString += new Date(dateFormatted.setDate(31)).toDateString();
                  } else {
                    dateFormatted.setDate(dateFormatted.getDate() + 1)
                    dateString += this.getNextTuesdayAfterDate(dateFormatted).toDateString();
                    dateFormatted.setDate(dateFormatted.getDate() - 1)
                  }
                }


                lastDate = dateFormatted;

                if (piecesRow.length > 0) {
                  tempData.push({
                    title: dateString,
                    pieces: piecesRow,
                    date: dateFormatted
                  })
                }
              }
            }
          } else if (this.categorySelected === "Profile Featured") {
            let lastDate = null;
            for (let i3 in this.jsonData["FeaturedData"]["results"]) {

              let dateItem = this.jsonData["FeaturedData"]["results"][i3]["creationDate"];

              let dateFormatted = new Date(dateItem)
              if (dateFormatted.getYear() > 120) {
                let piecesRow = []
                if (this.getNextTuesdayAfterDate(dateFormatted).getDate() < dateFormatted.getDate()) {
                  dateFormatted = this.getNextTuesdayAfterDate(dateFormatted);
                  dateFormatted.setDate(1);
                } else {
                  dateFormatted = this.getNextTuesdayAfterDate(dateFormatted);
                }

                const tagTypes = ["productIds", "orTags", "andTags"];

                if (this.jsonData["FeaturedData"]["results"][i3]["displayProperties"]["dressingRoomLayout"]["dressingRoomFeaturedQuery"]) {
                  for (let tagType of tagTypes) {
                    for (let i4 in this.jsonData["FeaturedData"]["results"][i3]["displayProperties"]["dressingRoomLayout"]["dressingRoomFeaturedQuery"][tagType]) {
                      let pieceInfo = this.lookForPiece(this.jsonData["FeaturedData"]["results"][i3]["displayProperties"]["dressingRoomLayout"]["dressingRoomFeaturedQuery"][tagType][i4]);

                      if (pieceInfo !== false) {
                        if (this.searchTerm !== "") {
                          if (pieceInfo.title["neutral"].toLowerCase().includes(this.searchTerm.toLowerCase())) {
                            piecesRow.push(pieceInfo);
                          }
                        } else {
                          piecesRow.push(pieceInfo);
                        }
                      }
                    }
                  }
                }


                let dateString;
                dateString = dateFormatted.toDateString() + " - "
                if (lastDate !== null) {
                  dateString += lastDate.toDateString();
                } else {
                  if ((new Date(dateFormatted.setDate(dateFormatted.getDate() + 7))).getDate()
                    < new Date(dateFormatted.setDate(dateFormatted.getDate() - 7)).getDate()) {
                    dateString += new Date(dateFormatted.setDate(31)).toDateString();
                  } else {
                    dateFormatted.setDate(dateFormatted.getDate() + 1)
                    dateString += this.getNextTuesdayAfterDate(dateFormatted).toDateString();
                    dateFormatted.setDate(dateFormatted.getDate() - 1)
                  }
                }

                lastDate = dateFormatted;


                if (piecesRow.length > 0) {
                  tempData.push({
                    title: dateString,
                    pieces: piecesRow,
                    date: dateFormatted
                  })
                }
              }
            }
          } else {
            let lastDate = null;
            for (let i5 in this.jsonData["FeaturedData"]["results"]) {

              let dateItem = this.jsonData["FeaturedData"]["results"][i5]["creationDate"];

              let dateFormatted = new Date(dateItem)
              if (dateFormatted.getYear() > 120) {
                let piecesRow = []
                if (this.getNextTuesdayAfterDate(dateFormatted).getDate() < dateFormatted.getDate()) {
                  dateFormatted = this.getNextTuesdayAfterDate(dateFormatted);
                  dateFormatted.setDate(1);
                } else {
                  dateFormatted = this.getNextTuesdayAfterDate(dateFormatted);
                }


                if (this.jsonData["FeaturedData"]["results"][i5]["displayProperties"]["dressingRoomLayout"]["categoryObjects"][1]["subCategories"][this.pathToData]["featuredQuery"]) {
                  for (let i6 in this.jsonData["FeaturedData"]["results"][i5]["displayProperties"]["dressingRoomLayout"]["categoryObjects"][1]["subCategories"][this.pathToData]["featuredQuery"]["productIds"]) {
                    let pieceInfo = this.lookForPiece(this.jsonData["FeaturedData"]["results"][i5]["displayProperties"]["dressingRoomLayout"]["categoryObjects"][1]["subCategories"][this.pathToData]["featuredQuery"]["productIds"][i6])

                    if (pieceInfo !== false) {
                      if (this.searchTerm !== "") {
                        if (pieceInfo.title["neutral"].toLowerCase().includes(this.searchTerm.toLowerCase())) {
                          piecesRow.push(pieceInfo)
                        }
                      } else {
                        piecesRow.push(pieceInfo)
                      }
                    }
                  }
                }

                //v2 format
                if (this.jsonData["FeaturedData"]["results"][i5]["displayProperties"]["dressingRoomLayout"]["categoryObjects"][0]["subCategories"][this.pathToData]["featuredQuery"]) {
                  const tagTypes = ["orTags", "andTags", "productIds"];

                  for (let tagType of tagTypes) {
                    for (let i6 in this.jsonData["FeaturedData"]["results"][i5]["displayProperties"]["dressingRoomLayout"]["categoryObjects"][0]["subCategories"][this.pathToData]["featuredQuery"][tagType]) {
                      let pieceInfo = this.lookForPiece(this.jsonData["FeaturedData"]["results"][i5]["displayProperties"]["dressingRoomLayout"]["categoryObjects"][0]["subCategories"][this.pathToData]["featuredQuery"][tagType][i6]);

                      if (pieceInfo !== false) {
                        if (this.searchTerm !== "") {
                          if (pieceInfo.title["neutral"].toLowerCase().includes(this.searchTerm.toLowerCase())) {
                            piecesRow.push(pieceInfo);
                          }
                        } else {
                          piecesRow.push(pieceInfo);
                        }
                      }
                    }
                  }
                }

                let dateString;
                dateString = dateFormatted.toDateString() + " - "
                if (lastDate !== null) {
                  dateString += lastDate.toDateString();
                } else {
                  if ((new Date(dateFormatted.setDate(dateFormatted.getDate() + 7))).getDate()
                    < new Date(dateFormatted.setDate(dateFormatted.getDate() - 7)).getDate()) {
                    dateString += new Date(dateFormatted.setDate(31)).toDateString();
                  } else {
                    dateFormatted.setDate(dateFormatted.getDate() + 1)
                    dateString += this.getNextTuesdayAfterDate(dateFormatted).toDateString();
                    dateFormatted.setDate(dateFormatted.getDate() - 1)
                  }
                }

                lastDate = dateFormatted;


                if (piecesRow.length > 0) {
                  tempData.push({
                    title: dateString,
                    pieces: piecesRow,
                    date: dateFormatted
                  })
                }
              }
            }
          }
        }

        this.jsonToRender = tempData.sort((a, b) => b.date - a.date)
        NProgress.done();
      }
    }
  }
}
</script>

<style scoped>
.select {
  -webkit-box-align: center;
  -webkit-box-pack: start;
  float: none;
}

.specialrow {
  margin-top: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
