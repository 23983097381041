<template xmlns:cursor="http://www.w3.org/1999/xhtml">
  <div v-bind="$attrs" class="container is-max-widescreen" style="">
      <nav class="breadcrumb has-dot-separator column is-full" aria-label="breadcrumbs">
        <ul>
          <li class=""><a v-on:click="navigateTo('')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-home" aria-hidden="true"></i>
            </span>
            Home
          </a></li>
          <li><a v-on:click="navigateTo('products')">
            <span class="icon is-small">
              <i class="fas fa-list" aria-hidden="true"></i>
            </span>
            Products
          </a></li>
          <li class=""><a v-on:click="navigateTo('featured')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-fire" aria-hidden="true"></i>
            </span>
            Featured
          </a></li>
          <li class=""><a href="#" aria-current="page">
            <span class="icon is-small">
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </span>
            Stats
          </a></li>
          <li class=""><a v-on:click="navigateTo('comparator')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-balance-scale-left" aria-hidden="true"></i>
            </span>
            Comparator
          </a></li>
          <li class=""><a v-bind:href="'https://patreon.com/PersonaExplorer'" target="_blank" aria-current="page">
              <span class="icon is-small">
                  <i class="fas fa-star"></i>
              </span>
            Premium Subscription
          </a></li>
        </ul>
      </nav>

    <div class="column is-full has-text-centered">
      <h5 class="has-text-white is-italic">All first party items are excluded from statistics computations.
      </h5>
    </div>

      <div v-if="$auth.isAuthenticated.value">
        <div v-if="isPremiumAccount">
          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <LineChart :chartData="marketSizeDataChart" :options="getOptionData('Size of the Persona catalog by month (free items & first party items excluded)')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full has-text-white">
            <BarChart :chartData="partnerDataChart" :options="getOptionData('Number of Persona items purchasable by partner this month')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full has-text-white">
            <BarChart :chartData="monthDataPublishedChart" :options="getOptionData('Number of Persona items published by month')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full has-text-white">
            <BarChart :chartData="monthDataRelistedChart" :options="getOptionData('Number of Persona items relisted by month')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full has-text-white">
            <BarChart :chartData="monthDataPublishedRelistedChart" :options="getOptionData('Number of Persona items published or relisted by month')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full has-text-white">
            <BarChart :chartData="monthDataDelistedChart" :options="getOptionData('Number of Persona items delisted by month')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="marketShareDataChart" :options="getOptionData('Marketshare between Microsoft and Partners of the Persona catalog by month', true)" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="rarityRepartitionDataChart" :options="getOptionData('Repartition of Persona item rarity by month', true)" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="wordsRepartitionDataChart" :options="getOptionData('Most Common Words')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="tagsRepartitionDataChart" :options="getOptionData('Most Common Tags')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="featuredPlusDataChart" :options="getOptionData('Top Featured+ Performers')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="featuredDataChart" :options="getOptionData('Top Featured Performers')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="extendedDataChart" :options="getOptionData('Top Extended Performers')" />
          </div>
          
          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="featuredPlusProportionnalDataChart" :options="getOptionData('Top Featured+ Performers / Team catalog size')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="featuredProportionnalDataChart" :options="getOptionData('Top Featured Performers / Team catalog size')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <BarChart :chartData="extendedProportionnalDataChart" :options="getOptionData('Top Extended Performers / Team catalog size')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <!-- Chart for Featured+ Ratio by PieceType -->
            <BarChart :chartData="featuredPlusRatioDataChart" :options="getOptionData('Featured+ Ratio by PieceType (%)')" />
          </div>

          <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
            <span v-if="$auth.isAuthenticated.value" class="select">
                <select @change="onCategoryChange($event)" v-model="categorySelected" class="custom-select">
                  <option cursor:pointer value="Top">Top</option>
                  <option cursor:pointer value="Back">Back</option>
                  <option cursor:pointer value="Bottom">Bottom</option>
                  <option cursor:pointer value="Outerwear">Outerwear</option>
                  <option cursor:pointer value="Hood">Hood</option>
                  <option cursor:pointer value="Dress">Dress</option>
                  <option cursor:pointer value="Headwear">Headwear</option>
                  <option cursor:pointer value="Hand">Hand</option>
                  <option cursor:pointer value="Arms">Arms</option>
                  <!-- <option cursor:pointer value="Gloves">Gloves</option> -->
                  <option cursor:pointer value="Feet">Feet</option>
                  <option cursor:pointer value="Legs">Legs</option>
                  <option cursor:pointer value="High Pants">High Pants</option>
                  <option cursor:pointer value="Face Accessory">Face Accessory</option>
                  <option cursor:pointer value="Facial Hair">Facial Hair</option>
                  <option cursor:pointer value="Hair">Hair</option>
                  <!-- <option cursor:pointer value="Back Accessory">Back Accessory</option> -->
                  <option cursor:pointer value="Emote">Emote</option>
                  <option cursor:pointer value="Mouth">Mouth</option>
                  <option cursor:pointer value="Skin">Skin</option>
                  <option cursor:pointer value="Eyes">Eyes</option>
                  <option cursor:pointer value="Capes">Capes</option>
                </select>
            </span>
            <LineChart :chartData="pieceTypeDataChart" :options="getOptionData('Category Distribution of Persona items by month')" />
          </div>
        </div>
        <div v-else class="column-error column is-full has-text-centered">
          <p class="has-text-white">You need a Premium subscription to this this page.</p>
          <p class="has-text-white">Subscribe here: <a href="https://patreon.com/PersonaExplorer" target="_blank">https://patreon.com/PersonaExplorer</a></p>
        </div>
      </div>
      <div v-else class="column-error column is-full has-text-centered">
        <!-- <p class="has-text-white">Please login to see this page (access restricted to <strong>Premium</strong> users only). </p> -->
        <p class="has-text-white">Please login to see this page. </p>
      </div>
    </div>
</template>
<script>
import {BarChart, LineChart} from "vue-chart-3";
import chartTrendline from "chartjs-plugin-trendline";
import {Chart, registerables} from 'chart.js';
import {useMeta} from "vue-meta";

Chart.register(chartTrendline);
Chart.register(...registerables);

export default {
  name: 'NewItems',
  props: {
    isPremiumAccount: Boolean,
    jsonData: Object
  },
  data(){
    return{
      monthDataPublishedChart: {},
      monthDataDelistedChart: {},
      monthDataRelistedChart: {},
      monthDataPublishedRelistedChart: {},
      partnerDataChart: {},
      conversionList: {},
      marketSizeDataChart: {},
      marketShareDataChart: {},
      pieceTypeDataChart: {},
      pieceTypeDataChart2: {},
      rarityRepartitionDataChart: {},
      wordsRepartitionDataChart: {},
      tagsRepartitionDataChart: {},
      featuredPlusDataChart: {},
      featuredDataChart: {},
      extendedDataChart: {},
      featuredPlusProportionnalDataChart: {},
      featuredProportionnalDataChart: {},
      extendedProportionnalDataChart: {},
      featuredPlusRatioDataChart: {},
      categorySelected: "Top",
    }
  },
  setup () {
    useMeta({
      title: 'Stats',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  components: {
    LineChart,
    BarChart
  },
  watch: {
    isPremiumAccount: function(newVal) {
      if(newVal){
        this.updatePage()
      }
    }
  },
  mounted() {
    this.conversionList = {
      "persona_top": "Top",
      "persona_back": "Back",
      "persona_bottom": "Bottom",
      "persona_outerwear": "Outerwear",
      "persona_gloves": "Gloves",
      "persona_face_accessory": "Face Accessory",
      "persona_back_accessory": "Back Accessory",
      "persona_hand": "Hand",
      "persona_head": "Headwear",
      "persona_facial_hair": "Facial Hair",
      "persona_emote": "Emote",
      "persona_feet": "Feet",
      "persona_mouth": "Mouth",
      "persona_legs": "Legs",
      "persona_arms": "Arms",
      "persona_hood": "Hood",
      "persona_dress": "Dress",
      "persona_high_pants": "High Pants",
      "persona_hair": "Hair",
      "persona_skin": "Skin",
      "persona_eyes": "Eyes",
      "persona_capes": "Capes"
    };
    if(this.isPremiumAccount){
       this.updatePage();
    }
  },
  methods: {
    navigateTo(path){
      this.$router.push({ path: "/" + path  })
    },
    updatePage(){
      let monthNamesList = []
      let monthNamesCountPurchasable = [];
      let monthNamesCountPurchasableMinecraft = [];
      let monthNamesCountPurchasableNotMinecraft = [];
      let monthNamesCountPurchasableMinecraftPercent = [];
      let monthNamesCountPurchasableNotMinecraftPercent = [];
      let monthNamesCountLegendary = []
      let monthNamesCountEpic = [];
      let monthNamesCountRare = [];
      let monthNamesCountCommon = [];
      let monthNamesCountUncommon = [];
      let monthNamesCountPublished = [];
      let monthNamesCountPublishedMinecraft = [];
      let monthNamesCountPublishedWithoutMinecraft = [];
      let monthNamesCountDelisted = [];
      let monthNamesCountDelistedMinecraft = [];
      let monthNamesCountDelistedWithoutMinecraft = [];
      let monthNamesCountRelisted = [];
      let monthNamesCountRelistedMinecraft = [];
      let monthNamesCountRelistedWithoutMinecraft = [];
      let monthNamesCountPublishedRelistedMinecraft = [];
      let monthNamesCountPublishedRelistedWithoutMinecraft = [];

      let featuredPlusCount = {}

      let partnerData = {}
      let wordCounts = {}
      let tagCounts = {}


      let extraTagsData = {}

      for(let i in this.jsonData["Pieces"]) {
        if (extraTagsData[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]] === undefined) {
            extraTagsData[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]] = [0, 0, 0];
        }
        if(this.jsonData["Pieces"][i]["extraTags"].includes("featured+") && !this.jsonData["Pieces"][i]["extraTags"].includes("first_party")){
           extraTagsData[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]][0]++;
        }
        if(this.jsonData["Pieces"][i]["extraTags"].includes("featured") && !this.jsonData["Pieces"][i]["extraTags"].includes("first_party")){
          extraTagsData[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]][1]++;
        }
        if(this.jsonData["Pieces"][i]["extraTags"].includes("extended") && !this.jsonData["Pieces"][i]["extraTags"].includes("first_party")){
          extraTagsData[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]][2]++;
        }

        if(this.jsonData["Pieces"][i]["displayProperties"]["purchasable"] === true && this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] !== "Minecraft"
            && this.jsonData["Pieces"][i]["displayProperties"]["price"] > 0 && !this.jsonData["Pieces"][i].extraTags.includes("first_party")){
          if (partnerData[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]] === undefined) {
            partnerData[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]] = 0;
          }
          partnerData[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]]++;
        }
        if(this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] !== "Minecraft" && this.jsonData["Pieces"][i].extraTags.includes("featured+")
            && !this.jsonData["Pieces"][i].extraTags.includes("first_party")){
          featuredPlusCount[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]] = 
            (featuredPlusCount[this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]] || 0) + 1
        }
        if(this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] !== "Minecraft" && !this.jsonData["Pieces"][i].extraTags.includes("first_party")){
          let words = this.jsonData["Pieces"][i].title.neutral.split(" ");
          for(var i2 = 0; i2 < words.length; i2++)
              wordCounts[words[i2]] = (wordCounts[words[i2]] || 0) + 1;

          for(var t in this.jsonData["Pieces"][i].tags)
              tagCounts[this.jsonData["Pieces"][i].tags[t]] = (tagCounts[this.jsonData["Pieces"][i].tags[t]] || 0) + 1;
        }
      }



      let featuredPlusData = Object.keys(extraTagsData).map(function(key) {
        return [key, extraTagsData[key][0]];
      });
      featuredPlusData.sort(function(first, second) {
        return second[1] - first[1];
      });
      featuredPlusData.shift()

      let featuredData = Object.keys(extraTagsData).map(function(key) {
        return [key, extraTagsData[key][1]];
      });
      featuredData.sort(function(first, second) {
        return second[1] - first[1];
      });
      featuredData.shift()

      let extendedData = Object.keys(extraTagsData).map(function(key) {
        return [key, extraTagsData[key][2]];
      });
      extendedData.sort(function(first, second) {
        return second[1] - first[1];
      });
      extendedData.shift()


      for(let i in extraTagsData){
        if(Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 1]["partnerData"][i]){
          extraTagsData[i][0] = Math.round(extraTagsData[i][0] / Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 1]["partnerData"][i][1] * 100);
          extraTagsData[i][1] = Math.round(extraTagsData[i][1] / Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 1]["partnerData"][i][1] * 100);
          extraTagsData[i][2] = Math.round(extraTagsData[i][2] / Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 1]["partnerData"][i][1] * 100);
        }else{
          delete extraTagsData[i]
        }
      }

      let featuredPlusDataProp = Object.keys(extraTagsData).map(function(key) {
        return [key, extraTagsData[key][0]];
      });
      featuredPlusDataProp.sort(function(first, second) {
        return second[1] - first[1];
      });
      featuredPlusDataProp.shift()

      let featuredDataProp = Object.keys(extraTagsData).map(function(key) {
        return [key, extraTagsData[key][1]];
      });
      featuredDataProp.sort(function(first, second) {
        return second[1] - first[1];
      });
      featuredDataProp.shift()

      let extendedDataProp = Object.keys(extraTagsData).map(function(key) {
        return [key, extraTagsData[key][2]];
      });
      extendedDataProp.sort(function(first, second) {
        return second[1] - first[1];
      });
      extendedDataProp.shift()





      //sorting
      let items = Object.keys(partnerData).map(function(key) {
        return [key, partnerData[key]];
      });

      // Sort the array based on the second element
      items.sort(function(first, second) {
        return second[1] - first[1];
      });

      var words = Object.keys(wordCounts).map(function(key) {
        return [key, wordCounts[key]];
      });

      // Sort the array based on the second element
      words.sort(function(first, second) {
        return second[1] - first[1];
      });

      var tags = Object.keys(tagCounts).map(function(key) {
        return [key, tagCounts[key]];
      });

      // Sort the array based on the second element
      tags.sort(function(first, second) {
        return second[1] - first[1];
      });

      let partnerNames = [];
      let partnerNamesData = [];

      for(let i in items){
        partnerNames.push(items[i][0])
        partnerNamesData.push(items[i][1])
      }


      //console.log(this.jsonData["Stats"])

      for(let i in Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 1]["partnerData"]){
        if(Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 2]["partnerData"][i]){
          if(Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 1]["partnerData"][i][0] < 5
         && Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 2]["partnerData"][i][0] < 5){
          //console.log("Potential client: " + i + " (total  catalog size: " + Object.values(this.jsonData["Stats"])[Object.values(this.jsonData["Stats"]).length - 1]["partnerData"][i][1] + ")") 
         }
        }
      }

      this.partnerDataChart = {
        labels: partnerNames,
        datasets: [
          {
            label: "Persona items purchasable by partner",
            data: partnerNamesData,
            borderColor: "#ff7777",
            backgroundColor: ['#ff7777'],
            hoverBackgroundColor: "#f33636",
            color: "white",

          },
        ],
      }
      if(this.isPremiumAccount) {
        for (let i = 0; i < Object.keys(this.jsonData["Stats"]).length; i++) {
          monthNamesList.push(Object.keys(this.jsonData["Stats"])[i])
          monthNamesCountPurchasable.push(Object.values(this.jsonData["Stats"])[i]["countPurchasable"])
          monthNamesCountPurchasableMinecraft.push((Object.values(this.jsonData["Stats"])[i]["countPurchasableMinecraft"]))
          monthNamesCountPurchasableNotMinecraft.push((Object.values(this.jsonData["Stats"])[i]["countPurchasableNotMinecraft"]))

          monthNamesCountPublished.push(Object.values(this.jsonData["Stats"])[i]["countItemsPublished"]);
          monthNamesCountPublishedMinecraft.push(Object.values(this.jsonData["Stats"])[i]["countItemsPublishedMinecraft"]);
          monthNamesCountPublishedWithoutMinecraft.push(Object.values(this.jsonData["Stats"])[i]["countItemsPublishedWithoutMinecraft"]);
          monthNamesCountDelisted.push(Object.values(this.jsonData["Stats"])[i]["countItemsDelisted"]);
          monthNamesCountDelistedMinecraft.push(Object.values(this.jsonData["Stats"])[i]["countItemsDelistedMinecraft"]);
          monthNamesCountDelistedWithoutMinecraft.push(Object.values(this.jsonData["Stats"])[i]["countItemsDelistedWithoutMinecraft"]);
          monthNamesCountRelisted.push(Object.values(this.jsonData["Stats"])[i]["countItemsRelisted"]);
          monthNamesCountRelistedMinecraft.push(Object.values(this.jsonData["Stats"])[i]["countItemsRelistedMinecraft"]);
          monthNamesCountPublishedRelistedMinecraft.push(Object.values(this.jsonData["Stats"])[i]["countItemsRelistedMinecraft"] + Object.values(this.jsonData["Stats"])[i]["countItemsPublishedMinecraft"]);
          monthNamesCountRelistedWithoutMinecraft.push(Object.values(this.jsonData["Stats"])[i]["countItemsRelistedWithoutMinecraft"]);
          monthNamesCountPublishedRelistedWithoutMinecraft.push(Object.values(this.jsonData["Stats"])[i]["countItemsRelistedWithoutMinecraft"] + Object.values(this.jsonData["Stats"])[i]["countItemsPublishedWithoutMinecraft"]);
          monthNamesCountLegendary.push(Math.floor(((Object.values(this.jsonData["Stats"])[i]["countLegendaryItems"]) / Object.values(this.jsonData["Stats"])[i]["countItemsPublished"]) * 100))
          monthNamesCountEpic.push(Math.floor(((Object.values(this.jsonData["Stats"])[i]["countEpicItems"]) / Object.values(this.jsonData["Stats"])[i]["countItemsPublished"]) * 100))
          monthNamesCountRare.push(Math.floor(((Object.values(this.jsonData["Stats"])[i]["countRareItems"]) / Object.values(this.jsonData["Stats"])[i]["countItemsPublished"]) * 100))
          monthNamesCountCommon.push(Math.floor(((Object.values(this.jsonData["Stats"])[i]["countCommonItems"]) / Object.values(this.jsonData["Stats"])[i]["countItemsPublished"]) * 100))
          monthNamesCountUncommon.push(Math.floor(((Object.values(this.jsonData["Stats"])[i]["countUncommonItems"]) / Object.values(this.jsonData["Stats"])[i]["countItemsPublished"]) * 100))

          monthNamesCountPurchasableMinecraftPercent.push(Math.round((Object.values(this.jsonData["Stats"])[i]["countPurchasableMinecraft"] / Object.values(this.jsonData["Stats"])[i]["countPurchasable"]) * 100))
          monthNamesCountPurchasableNotMinecraftPercent.push(Math.round((Object.values(this.jsonData["Stats"])[i]["countPurchasableNotMinecraft"] / Object.values(this.jsonData["Stats"])[i]["countPurchasable"]) * 100))
        }


        this.monthDataPublishedChart = {
          labels: monthNamesList,
          datasets: [
            {
              label: "Number of Persona published (Minecraft)",
              data: monthNamesCountPublishedMinecraft,
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
              trendlineLinear: {
                style: "#3e95cd",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            },
            {
              label: "Number of Persona published (Partners)",
              data: monthNamesCountPublishedWithoutMinecraft,
              borderColor: "#ff7777",
              backgroundColor: ['#ff7777'],
              hoverBackgroundColor: "#f33636",
              trendlineLinear: {
                style: "#f19d9d",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            },
          ],
        };

        this.monthDataDelistedChart = {
          labels: monthNamesList,
          datasets: [
            {
              label: "Number of Persona delisted (Minecraft)",
              data: monthNamesCountDelistedMinecraft,
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
              trendlineLinear: {
                style: "#3e95cd",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            },
            {
              label: "Number of Persona delisted (Partners)",
              data: monthNamesCountDelistedWithoutMinecraft,
              borderColor: "#ff7777",
              backgroundColor: ['#ff7777'],
              hoverBackgroundColor: "#f33636",
              trendlineLinear: {
                style: "#f19d9d",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            },
          ],
        };

        this.monthDataRelistedChart = {
          labels: monthNamesList,
          datasets: [
            {
              label: "Number of Persona relisted (Minecraft)",
              data: monthNamesCountRelistedMinecraft,
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
              trendlineLinear: {
                style: "#3e95cd",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            },
            {
              label: "Number of Persona relisted (Partners)",
              data: monthNamesCountRelistedWithoutMinecraft,
              borderColor: "#ff7777",
              backgroundColor: ['#ff7777'],
              hoverBackgroundColor: "#f33636",
              trendlineLinear: {
                style: "#f19d9d",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            },
          ],
        };

        this.monthDataPublishedRelistedChart = {
          labels: monthNamesList,
          datasets: [
            {
              label: "Number of Persona published/relisted (Minecraft)",
              data: monthNamesCountPublishedRelistedMinecraft,
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
              trendlineLinear: {
                style: "#3e95cd",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            },
            {
              label: "Number of Persona published/relisted (Partners)",
              data: monthNamesCountPublishedRelistedWithoutMinecraft,
              borderColor: "#ff7777",
              backgroundColor: ['#ff7777'],
              hoverBackgroundColor: "#f33636",
              trendlineLinear: {
                style: "#f19d9d",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            },
          ],
        };

        this.marketSizeDataChart = {
            labels: monthNamesList,
            datasets: [
              {
                label: "Catalog size of Minecraft roster",
                data: monthNamesCountPurchasableMinecraft,
                borderColor: "#ff7777",
                backgroundColor: ['#ff7777'],
                hoverBackgroundColor: "#f33636",
                yAxisID: 'y',
              },
              {
                label: "Global catalog size",
                data: monthNamesCountPurchasable,
                borderColor: "#6e6e6e",
                backgroundColor: ['#6e6e6e'],
                hoverBackgroundColor: "#4b4a4a",
                yAxisID: 'y2',
                trendlineLinear: {
                  style: "#888888",
                  lineStyle: "dotted",
                  width: 2,
                  projection: false
                }
              },
              {
              label: "Catalog size of Partners roster",
              data: monthNamesCountPurchasableNotMinecraft,
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
              yAxisID: 'y'
            }
            ],
          };

        this.marketShareDataChart = {
          labels: monthNamesList,
          datasets: [
            {
              label: "Microsoft Marketshare",
              data: monthNamesCountPurchasableMinecraftPercent,
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
            },
            {
              label: "Partners Marketshare",
              data: monthNamesCountPurchasableNotMinecraftPercent,
              borderColor: "#ff7777",
              backgroundColor: ['#ff7777'],
              hoverBackgroundColor: "#f33636",
            },
          ],
        };

        this.rarityRepartitionDataChart = {
          labels: monthNamesList,
          datasets: [
            {
              label: "Legendary Items",
              data: monthNamesCountLegendary,
              borderColor: "#ab7b56",
              backgroundColor: ['#ab7b56'],
              hoverBackgroundColor: "#835e43",
            },
            {
              label: "Epic Items",
              data: monthNamesCountEpic,
              borderColor: "#AA3CC6",
              backgroundColor: ['#AA3CC6'],
              hoverBackgroundColor: "#1d0e20",
            },
            {
              label: "Rare Items",
              data: monthNamesCountRare,
              borderColor: "#1a409b",
              backgroundColor: ['#1a409b'],
              hoverBackgroundColor: "#0a193d",
            },
            {
              label: "Uncommon Items",
              data: monthNamesCountUncommon,
              borderColor: "#6e6e6e",
              backgroundColor: ['#6e6e6e'],
              hoverBackgroundColor: "#4b4b4b",
            },
            {
              label: "Common Items",
              data: monthNamesCountCommon,
              borderColor: "#214029",
              backgroundColor: ['#214029'],
              hoverBackgroundColor: "#132517",
            }
          ],
        };

        this.wordsRepartitionDataChart = {
          labels: words.slice(0, 20).map((element) => element[0]),
          datasets: [
            {
              label: "Count",
              data: words.slice(0, 20).map((element) => element[1]),
              borderColor: ["#2c69e1"],
              backgroundColor: ["#2c69e1"],
              hoverBackgroundColor: ["#1134E1"],
              color: "white",

            }
          ],
        }

        this.tagsRepartitionDataChart = {
          labels: tags.slice(0, 20).map((element) => element[0]),
          datasets: [
            {
              label: "Count",
              data: tags.slice(0, 20).map((element) => element[1]),
              borderColor: ["#2c69e1"],
              backgroundColor: ["#2c69e1"],
              hoverBackgroundColor: ["#1134E1"],
              color: "white",

            }
          ],
        }

        this.featuredPlusDataChart = {
          labels: featuredPlusData.map((element) => element[0]),
          datasets: [
            {
              label: "Count",
              data: featuredPlusData.map((element) => element[1]),
              borderColor: ["#2c69e1"],
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#1134E1",
              color: "white",

            }
          ],
        }

        this.featuredDataChart = {
          labels: featuredData.map((element) => element[0]),
          datasets: [
            {
              label: "Count",
              data: featuredData.map((element) => element[1]),
              borderColor: ["#2c69e1"],
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#1134E1",
              color: "white",

            }
          ],
        }

        this.extendedDataChart = {
          labels: extendedData.map((element) => element[0]),
          datasets: [
            {
              label: "Count",
              data: extendedData.map((element) => element[1]),
              borderColor: ["#2c69e1"],
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#1134E1",
              color: "white",

            }
          ],
        }

        this.featuredPlusProportionnalDataChart = {
          labels: featuredPlusDataProp.map((element) => element[0]),
          datasets: [
            {
              label: "Percentage",
              data: featuredPlusDataProp.map((element) => element[1]),
              borderColor: ["#2c69e1"],
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#1134E1",
              color: "white",

            }
          ],
        }

        this.featuredProportionnalDataChart = {
          labels: featuredDataProp.map((element) => element[0]),
          datasets: [
            {
              label: "Percentage",
              data: featuredDataProp.map((element) => element[1]),
              borderColor: ["#2c69e1"],
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#1134E1",
              color: "white",

            }
          ],
        }

        this.extendedProportionnalDataChart = {
          labels: extendedDataProp.map((element) => element[0]),
          datasets: [
            {
              label: "Percentage",
              data: extendedDataProp.map((element) => element[1]),
              borderColor: ["#2c69e1"],
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#1134E1",
              color: "white",

            }
          ],
        }
      }
      

      // Combine piece types, ratios, and counts into an array of objects
      let combinedData = Object.keys(this.jsonData["Global"]["featuredPlusRatioByPieceType"]).map(key => {
        return {
          pieceType: key,
          ratio: this.jsonData["Global"]["featuredPlusRatioByPieceType"][key],
          count: this.jsonData["Global"]["featuredPlusCountByPieceType"][key] || 0 // Default to 0 if no count
        };
      });

      combinedData = combinedData.filter(data => data.ratio > 0);
      combinedData.sort((a, b) => b.ratio - a.ratio);

      // Extract sorted piece types, ratios, and counts
      let sortedPieceTypes = combinedData.map(data => data.pieceType);
      let sortedRatios = combinedData.map(data => Math.round(data.ratio * 100) / 100);
      let sortedCounts = combinedData.map(data => data.count);

      // Define chart data with sorted values and updated labels showing piece counts
      this.featuredPlusRatioDataChart = {
        labels: sortedPieceTypes.map((pieceType, index) => `${pieceType} (${sortedCounts[index]} f+)`), // Show number of pieces in labels
        datasets: [
          {
            label: "Featured+ Ratio (%)",
            data: sortedRatios, // Use the sorted ratios
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            borderWidth: 1
          }
        ]
      };

      
      this.onCategoryChange();
    },
    onCategoryChange(){
      let categoryName = "";

      for (let i = 0; i < Object.keys(this.conversionList).length; i++) {
        if(Object.values(this.conversionList)[i] === this.categorySelected){
          categoryName = Object.keys(this.conversionList)[i]
        }
      }

      if(this.isPremiumAccount){
        if(categoryName !== ""){
          let monthNamesList = []

          for (let i = 0; i < Object.keys(this.jsonData["Stats"]).length; i++) {
            monthNamesList.push(Object.keys(this.jsonData["Stats"])[i])
          }

          this.pieceTypeDataChart = {
            labels: monthNamesList,
            datasets: [
              {
                label: "Number of " + this.categorySelected + " items published",
                data: this.getPieceTypeData(categoryName),
                borderColor: "#ff7777",
                backgroundColor: ['#ff7777'],
                hoverBackgroundColor: "#f33636",
                trendlineLinear: {
                  style: "#f19d9d",
                  lineStyle: "dotted",
                  width: 2,
                  projection: false
                }
              },
            ],
          };
        }
      }
    },
    getPieceTypeData(identificator){
      let toReturn = []
      for (let i = 0; i < Object.keys(this.jsonData["Stats"]).length; i++) {
        toReturn.push(Object.values(this.jsonData["Stats"])[i]["categoryData"][identificator])
      }
      return toReturn;
    },
    getOptionDataMultiAxis(title, chartBar = false){
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            position: "left",
            beginAtZero: true,
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          },
          y2: {
            position: "right",
            beginAtZero: true,
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          },
          x: {
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: "#9ca3af",
            },
            position: 'top'
          },
          title: {
            display: true,
            color: "#e5e0e0",
            text: title
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (context.parsed.y !== null && chartBar) {
                  label += ': ' +context.parsed.y + '%';
                }else{
                  label += ': ' + context.parsed.y;
                }
                return label;
              }
            }
          }
        }
      }
    },
    getOptionData(title, chartBar = false){
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          },
          x: {
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: "#9ca3af",
            },
            position: 'top'
          },
          title: {
            display: true,
            color: "#e5e0e0",
            text: title
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (context.parsed.y !== null && chartBar) {
                  label += ': ' +context.parsed.y + '%';
                }else{
                  label += ': ' + context.parsed.y;
                }
                return label;
              }
            }
          }
        }
      }
    }
  }
}
</script>
