<template>
  <div class="">
    <input
        type="search"
        v-model="searchText"
        class="input has-text-white"
        @focus="displayResults"
        @blur="hideResults"
    />
    <div class="dropdown-content" v-if="showResults">
      <a  v-for="item in filteredResults()"
          :key="item"
          class="dropdown-item"
          @click="clickItem(item)"
          @mousedown.prevent
      >{{ displayItem(item) }}
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: "AutoComplete",
  props: {
    dataDropdown: Array,
    clearUI: Boolean,
    closeUI: Boolean,
    setText: String
  },
  emits: ["clickItem", "uiCleared", "uiClosed"],
  watch: { 
    clearUI: function(newVal){
      if(newVal === true){
        this.clearInput()
      }
    },
    closeUI: function(newVal){
      if(newVal === true){
        this.closeDropdown()
      }
    },
    setText: function(newVal){
      if(newVal !== ""){
        this.setSearchText(newVal)
      }
    },
  },
  created() {
    this.setSearchText(this.setText)
  },
  methods: {
    clearInput(){
      this.searchText = ""
      this.hideResults()
      this.$emit('uiCleared', true)
    },
    setSearchText(data){
      this.searchText = data
    },
    closeDropdown(){
      this.hideResults()
      this.$emit('uiClosed', true)
    },
    displayItem(item){
        return item
    },
    clickItem(data) {
        this.searchText = data
        this.hideResults()
        this.$emit('clickItem', data)
    },
    hideResults(){
        this.showResults = false
    },
    displayResults(){
        if(this.dataDropdown.length > 0){
            this.showResults = true
        }
    },
    filteredResults(){
        return this.dataDropdown.slice(0, this.max)
    }
  },
  data() {
    //default config
    return {
        max: 3000,
        showResults: false,
        searchText: ""
    }
  }
}
</script>

<style scoped>
.dropdown-content {
  max-height: 10em;
  overflow: auto;
  position:absolute;
  z-index: 1;
}
</style>