<template xmlns:cursor="http://www.w3.org/1999/xhtml">
  <div v-bind="$attrs" class="container is-max-widescreen" style="">
    <nav class="breadcrumb has-dot-separator column is-full" aria-label="breadcrumbs">
      <ul>
        <ul>
          <li class=""><a v-on:click="navigateTo('')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-home" aria-hidden="true"></i>
            </span>
            Home
          </a></li>
          <li><a v-on:click="navigateTo('products')">
            <span class="icon is-small">
              <i class="fas fa-list" aria-hidden="true"></i>
            </span>
            Products
          </a></li>
          <li class=""><a v-on:click="navigateTo('featured')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-fire" aria-hidden="true"></i>
            </span>
            Featured
          </a></li>
          <li class=""><a v-on:click="navigateTo('stats')" aria-current="page">
            <span class="icon is-small">
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </span>
            Stats
          </a></li>
          <li class=""><a href="#" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-balance-scale-left" aria-hidden="true"></i>
            </span>
            Comparator
          </a></li>
          <li class=""><a v-bind:href="'https://patreon.com/PersonaExplorer'" target="_blank" aria-current="page">
              <span class="icon is-small">
                  <i class="fas fa-star"></i>
              </span>
            Premium Subscription
          </a></li>
        </ul>
      </ul>
    </nav>

    <div class="column is-full has-text-centered">
      <h5 class="has-text-white is-italic">All first party items are excluded from statistics computations.
      </h5>
    </div>

    <div v-if="$auth.isAuthenticated.value">
      <div class="columns is-multiline is-centered" v-if="isPremiumAccount">

        <div class="column is-one-third is-left fa-pull-left is-justify-content-left">
          <label class="label has-text-white">Select Team 1</label>
          <div class="field">
            <div class="control is-clearfix">
              <AutoComplete
                  :dataDropdown="dataList" v-debounce:500ms="textHandler" @clickItem="clickHandler" @uiCleared="uiCleared" @textSet="textSet" @uiClosed="uiClosed" :clearUI="shouldClearUI" :closeUI="shouldCloseUI" :setText="setTextAutoComplete"
              ></AutoComplete>
            </div>
          </div>
        </div>

        <div class="column is-one-third">
          <label class="label has-text-white">Select Team 2</label>
          <div class="field">
            <div class="control is-clearfix">
              <AutoComplete
                  :dataDropdown="dataList2" v-debounce:500ms="textHandler2" @clickItem="clickHandler2" @uiCleared="uiCleared2" @textSet="textSet2" @uiClosed="uiClosed2" :clearUI="shouldClearUI2" :closeUI="shouldCloseUI2" :setText="setTextAutoComplete2"
              ></AutoComplete>
            </div>
          </div>
        </div>

        <div v-if="selectedTeamOne !== ''" class="column is-full is-centered mt-5">
          <div class="content has-text-centered has-text-white">

            <h1 v-if="selectedTeamTwo !== ''" class="is-centered has-text-white has-text-weight-bold mb-5">
              <span style="color:#ff7777">{{selectedTeamOne }}</span> vs
              <span style="color:#2c69e1">{{selectedTeamTwo }}</span>
            </h1>
            <h1 v-else class="is-centered has-text-white has-text-weight-bold mb-5">
              <span style="color:#ff7777">{{selectedTeamOne}}</span>
            </h1>

            <div class="card mb-5">
              <div class="card-content">
                <h2 class="is-centered has-text-white">Statistics</h2>
                <div v-if="selectedTeamTwo !== ''" class="content has-text-white">
                  <div class="columns is-multiline is-centered">
                    <div class="column is-one-third">
                      <div class="box has-text-white mb-1" style="background-color: #1c1e24">
                        <BarChart :chartData="numberItemsPurchasableChart" :options="getOptionDataDefault('Number of items purchasable')" />
                      </div>

                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white mb-1" style="background-color: #1c1e24">
                        <BarChart :chartData="numberItemsPublishedChart" :options="getOptionDataDefault('Number of items published')" />
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white mb-1" style="background-color: #1c1e24">
                        <BarChart :chartData="numberItemsFeaturedPChart" :options="getOptionDataDefault('Number of Featured+ items')" />
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        <BarChart :chartData="numberItemsFeaturedChart" :options="getOptionDataDefault('Number of Featured items')" />
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        <BarChart :chartData="numberItemsExtendedChart" :options="getOptionDataDefault('Number of Extended items')" />
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        <BarChart :chartData="rarityItemDistribution" :options="getOptionDataDefault('Rarity Distribution', true)" />
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        <DoughnutChart :chartData="teamOneItemDistribution" :options="getOptionDataDoughnut(this.selectedTeamOne + ' Item Distribution')" />
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        <DoughnutChart :chartData="teamTwoItemDistribution" :options="getOptionDataDoughnut(this.selectedTeamTwo + ' Item Distribution')" />
                      </div>
                    </div>
                    <div class="column is-half">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        <BarChart :chartData="teamOneWordsDistribution" :options="getOptionDataDefault(this.selectedTeamOne + ' Most Common Words')" />
                      </div>
                    </div>
                    <div class="column is-half">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        <BarChart :chartData="teamTwoWordsDistribution" :options="getOptionDataDefault(this.selectedTeamTwo + ' Most Common Words')" />
                      </div>
                    </div>
                  </div>


                </div>
                <div v-else class="content has-text-white">
                  <div class="columns is-multiline is-centered">
                    <div class="column is-one-third">
                      <div class="box has-text-white mb-1" style="background-color: #1c1e24">
                        Number of items purchasable this month<br><span style="color:#ff7777">{{ countPurchasable }}</span>
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white mb-1" style="background-color: #1c1e24">
                        Total published items<br><span style="color:#ff7777">{{ countPublished }}</span>
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white mb-1" style="background-color: #1c1e24">
                        Number of Featured+ items<br><span style="color:#ff7777">{{countFPlus}} ({{Math.round(countFPlus / countPublished * 100)}} % rate) </span>
                        <span v-if="Math.round(countFPlus / countPublished * 100) > Math.round(countFPlusMonthBefore / countPublishedMonthBefore * 100)" style="color: green; border-bottom: 0px;" class="has-tooltip-arrow has-tooltip-multiline"  data-tooltip="Trend over the last three months."><i class="fas fa-arrow-up" aria-hidden="true"></i></span>
                        <span v-else-if="Math.round(countFPlus / countPublished * 100) == Math.round(countFPlusMonthBefore / countPublishedMonthBefore * 100)" class="has-tooltip-arrow has-tooltip-multiline"  data-tooltip="Trend over the last three months." style="border-bottom: 0px;"><i class="fas fa-equals" aria-hidden="true"></i></span>
                        <span v-else style="color: red; border-bottom: 0px;" class="has-tooltip-arrow has-tooltip-multiline"  data-tooltip="Trend over the last three months."><i class="fas fa-arrow-down" aria-hidden="true"></i></span>
                      </div>

                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        Number of Featured items<br><span style="color:#ff7777">{{countF}} ({{Math.round(countF / countPublished * 100)}} % rate) </span>
                        <span v-if="Math.round(countF / countPublished * 100) > Math.round(countFMonthBefore / countPublishedMonthBefore * 100)" style="color: green; border-bottom: 0px;" class="has-tooltip-arrow has-tooltip-multiline"  data-tooltip="Trend over the last three months."><i class="fas fa-arrow-up" aria-hidden="true"></i></span>
                        <span v-else-if="Math.round(countF / countPublished * 100) == Math.round(countFMonthBefore / countPublishedMonthBefore * 100)" class="has-tooltip-arrow has-tooltip-multiline" data-tooltip="Trend over the last three months." style="border-bottom: 0px;"><i class="fas fa-equals" aria-hidden="true"></i></span>
                        <span v-else style="color: red; border-bottom: 0px;" class="has-tooltip-arrow has-tooltip-multiline"  data-tooltip="Trend over the last three months."><i class="fas fa-arrow-down" aria-hidden="true"></i></span>
                      </div>
                    </div>
                    <div class="column is-one-third">
                      <div class="box has-text-white" style="background-color: #1c1e24">
                        Number of Extended items<br><span style="color:#ff7777">{{countExtended}} ({{Math.round(countExtended / countPublished * 100)}} % rate) </span>
                        <span v-if="Math.round(countExtended / countPublished * 100) > Math.round(countExtendedMonthBefore / countPublishedMonthBefore * 100)" style="color: green; border-bottom: 0px;" class="has-tooltip-arrow has-tooltip-multiline"  data-tooltip="Trend over the last three months."><i class="fas fa-arrow-up" aria-hidden="true"></i></span>
                        <span v-else-if="Math.round(countExtended / countPublished * 100) == Math.round(countExtendedMonthBefore / countPublishedMonthBefore * 100)" class="has-tooltip-arrow has-tooltip-multiline"  data-tooltip="Trend over the last three months." style="border-bottom: 0px;"><i class="fas fa-equals" aria-hidden="true"></i></span>
                        <span v-else style="color: red; border-bottom: 0px;" class="has-tooltip-arrow has-tooltip-multiline"  data-tooltip="Trend over the last three months."><i class="fas fa-arrow-down" aria-hidden="true"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full mb-4">
          <LineChart :chartData="monthDataPublishedChart" :options="getOptionDataMultiAxis('Team(s) Catalog growth vs Market Size growth')" />
        </div>


        <div v-if="$auth.isAuthenticated.value" class="column is-multiline is-centered is-full">
          <LineChart :chartData="monthDataRankingChart" :options="getOptionData('Evolution of the monthly ranking ' +
           '(according to the number of purchasable pieces)')" />
        </div>

        <div class="column is-full is-centered">
          <BarChart :chartData="numberItemsFeaturedPMonthly" :options="getOptionDataDefault('Monthly number of items Featured+')" />
        </div>
        <div class="column is-full is-centered">
          <BarChart :chartData="numberItemsFeaturedMonthly" :options="getOptionDataDefault('Monthly number of items Featured')" />
        </div>
        <div class="column is-full is-centered">
         <BarChart :chartData="numberItemsExtendedMonthly" :options="getOptionDataDefault('Monthly number of items Extended')" />
        </div>


      </div>
      <div v-else class="column-error column is-full has-text-centered">
        <p class="has-text-white">You need a Premium subscription to this this page.</p>
        <p class="has-text-white">Subscribe here: <a href="https://patreon.com/PersonaExplorer" target="_blank">https://patreon.com/PersonaExplorer</a></p>
      </div>
    </div>
    <div v-else class="column-error column is-full has-text-centered">
      <!-- <p class="has-text-white">Please login to see this page (access restricted to <strong>Premium</strong> users only). </p> -->
      <p class="has-text-white">Please login to see this page. </p>
    </div>
  </div>
</template>
<script>
import {BarChart, LineChart, DoughnutChart} from "vue-chart-3";
import chartTrendline from "chartjs-plugin-trendline";
import AutoComplete from '../components/AutoComplete.vue'
import {Chart, registerables} from 'chart.js';
import NProgress from "nprogress";
import {useMeta} from "vue-meta";

Chart.register(chartTrendline);
Chart.register(...registerables);

export default {
  name: 'ComparatorView',
  props: {
    isPremiumAccount: Boolean,
    jsonData: Object
  },
  data(){
    return{
      conversionList: {},
      setTextAutoComplete: "",
      setTextAutoComplete2: "",
      partnerList: [],
      dataList: [],
      dataList2: [],
      shouldClearUI: false,
      shouldCloseUI: false,
      shouldClearUI2: false,
      shouldCloseUI2: false,
      selectedTeamOne: "",
      selectedTeamTwo: "",
      monthDataPublishedChart: {},
      monthDataRankingChart: {},
      numberItemsPurchasableChart: {},
      numberItemsPublishedChart: {},
      numberItemsFeaturedPChart: {},
      numberItemsFeaturedChart: {},
      numberItemsExtendedChart: {},
      rarityItemDistribution: {},
      teamOneItemDistribution: {},
      teamTwoItemDistribution: {},
      teamOneWordsDistribution: {},
      teamTwoWordsDistribution: {},
      teamOneFeaturedPlusDistribution: {},
      teamTwoFeaturedPlusDistribution: {},
      numberItemsFeaturedPMonthly: {},
      numberItemsFeaturedMonthly: {},
      numberItemsExtendedMonthly: {},
      countPurchasable: 0,
      countPublished: 0,
      countPurchasableMonthBefore: 0,
      countPublishedMonthBefore: 0,
      countF: 0,
      countFPlus: 0,
      countFMonthBefore: 0,
      countFPlusMonthBefore: 0,
      countExtended: 0,
      countExtendedMonthBefore: 0,
      countPurchasable2: 0,
      countPublished2: 0,
      countF2: 0,
      countFPlus2: 0,
      countExtended2: 0,
      countPurchasable2MonthBefore: 0,
      countPublished2MonthBefore: 0,
      countF2MonthBefore: 0,
      countFPlus2MonthBefore: 0,
      countExtended2MonthBefore: 0
    }
  },
  setup () {
    useMeta({
      title: 'Comparator',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  components: {
    LineChart,
    BarChart,
    DoughnutChart,
    AutoComplete
  },
  watch: {
    isPremiumAccount: function(newVal) {
      if(newVal){
        this.updatePage()
      }
    }
  },
  mounted() {
    this.conversionList = {
      "persona_top": "Top",
      "persona_back": "Back",
      "persona_bottom": "Bottom",
      "persona_outerwear": "Outerwear",
      "persona_gloves": "Gloves",
      "persona_face_accessory": "Face Accessory",
      "persona_back_accessory": "Back Accessory",
      "persona_hand": "Hand",
      "persona_head": "Headwear",
      "persona_facial_hair": "Facial Hair",
      "persona_emote": "Emote",
      "persona_feet": "Feet",
      "persona_mouth": "Mouth",
      "persona_legs": "Legs",
      "persona_arms": "Arms",
      "persona_hood": "Hood",
      "persona_dress": "Dress",
      "persona_high_pants": "High Pants",
      "persona_hair": "Hair",
      "persona_skin": "Skin",
      "persona_eyes": "Eyes",
      "persona_capes": "Capes"
    };
    for(let i in this.jsonData["Pieces"]) {
      if (!this.partnerList.includes(this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]) && typeof this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] != "undefined") {
        this.partnerList.push(this.jsonData["Pieces"][i]["displayProperties"]["creatorName"])
      }
    }
    this.partnerList = this.partnerList.sort((a, b) => a.localeCompare(b))
    this.dataList = this.partnerList;
    this.dataList2 = this.partnerList;
    this.updatePage();
  },
  methods: {
    navigateTo(path){
      this.$router.push({ path: "/" + path  })
    },
    textHandler(data){
      if(this.dataList.includes(data)){
        this.clickHandler(data)
        this.shouldCloseUI = true
      }else{
        if(data === "" && this.selectedTeamOne !== ""){
          this.clickHandler(data)
        }
        this.dataList = this.partnerList.filter(element => element.toString().toLowerCase().includes(data.toLowerCase()))
      }
    },
    textHandler2(data){
      if(this.dataList2.includes(data)){
        this.clickHandler2(data)
        this.shouldCloseUI2 = true
      }else{
        if(data === "" && this.selectedTeamTwo !== ""){
          this.clickHandler2(data)
        }
        this.dataList2 = this.partnerList.filter(element => element.toString().toLowerCase().includes(data.toLowerCase()))
      }
    },
    clickHandler(data){
      this.selectedTeamOne = data
      this.updatePage()
    },
    clickHandler2(data){
      this.selectedTeamTwo = data
      this.updatePage()
    },
    uiCleared(){
      this.shouldClearUI = false
    },
    uiClosed(){
      this.shouldCloseUI = false
    },
    clearUI(){
      this.shouldClearUI = true
    },
    textSet(){
      this.setTextAutoComplete = ""
    },
    uiCleared2(){
      this.shouldClearUI = false
    },
    uiClosed2(){
      this.shouldCloseUI = false
    },
    clearUI2(){
      this.shouldClearUI = true
    },
    textSet2(){
      this.setTextAutoComplete = ""
    },
    updatePage(){
      if(this.isPremiumAccount && this.selectedTeamOne !== "") {
        if(this.partnerList.includes(this.selectedTeamOne)) {
          NProgress.start()


          let monthData = [];
          let monthDataTwo = [];
          let monthNames = [];
          let monthNamesCountPurchasable = [];

          let featuredPlusData = [];
          let featuredData = [];
          let extendedData = [];

          let featuredPlusDataTwo = [];
          let featuredDataTwo = [];
          let extendedDataTwo = [];
          
          for (let i = 0; i < Object.keys(this.jsonData["Stats"]).length; i++) {
            if(!featuredPlusData[Object.keys(this.jsonData["Stats"])[i]]){
              featuredPlusData[Object.keys(this.jsonData["Stats"])[i]] = 0
            }
            if(!featuredData[Object.keys(this.jsonData["Stats"])[i]]){
              featuredData[Object.keys(this.jsonData["Stats"])[i]] = 0
            }
            if(!extendedData[Object.keys(this.jsonData["Stats"])[i]]){
              extendedData[Object.keys(this.jsonData["Stats"])[i]] = 0
            }

            if(!featuredPlusDataTwo[Object.keys(this.jsonData["Stats"])[i]]){
              featuredPlusDataTwo[Object.keys(this.jsonData["Stats"])[i]] = 0
            }
            if(!featuredDataTwo[Object.keys(this.jsonData["Stats"])[i]]){
              featuredDataTwo[Object.keys(this.jsonData["Stats"])[i]] = 0
            }
            if(!extendedDataTwo[Object.keys(this.jsonData["Stats"])[i]]){
              extendedDataTwo[Object.keys(this.jsonData["Stats"])[i]] = 0
            }

            monthNames.push(Object.keys(this.jsonData["Stats"])[i])
            monthNamesCountPurchasable.push(Object.values(this.jsonData["Stats"])[i]["countPurchasable"])
            if (Object.values(this.jsonData["Stats"])[i]["partnerData"][this.selectedTeamOne]) {
              monthData.push(Object.values(this.jsonData["Stats"])[i]["partnerData"][this.selectedTeamOne][0]);
            }else if(this.partnerList.includes(this.selectedTeamOne)){
            //it means we don't have data for this month but it exists
            monthData.push(0);
            }
            if (Object.values(this.jsonData["Stats"])[i]["partnerData"][this.selectedTeamTwo]) {
              monthDataTwo.push(Object.values(this.jsonData["Stats"])[i]["partnerData"][this.selectedTeamTwo][0]);
            }else if(this.partnerList.includes(this.selectedTeamTwo)){
              //it means we don't have data for this month but it exists
              monthDataTwo.push(0);
            }
          }

          let lastThreeMonths = monthNames.slice(-3)

          this.countPurchasable = monthData[monthData.length - 1];
          this.countPurchasable2 = monthDataTwo[monthDataTwo.length - 1];

          let count = 0;
          let countF = 0;
          let countFPlus = 0;
          let countExtended = 0;

          let countMonthBefore = 0;
          let countFMonthBefore = 0;
          let countFPlusMonthBefore = 0;
          let countExtendedMonthBefore = 0;

          for(let i in this.jsonData["Pieces"]) {
            if(this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] === this.selectedTeamOne){

              if(this.jsonData["Pieces"][i]["extraTags"].includes("featured+")){
                countFPlus++;

                if(!lastThreeMonths.includes(this.jsonData["Pieces"][i]["monthCreated"])){
                  countFPlusMonthBefore++;
                }
                
                if(featuredPlusData[this.jsonData["Pieces"][i]["monthCreated"]]){
                  featuredPlusData[this.jsonData["Pieces"][i]["monthCreated"]] += 1;
                }else{
                  featuredPlusData[this.jsonData["Pieces"][i]["monthCreated"]] = 1;
                }
              }
              if(this.jsonData["Pieces"][i]["extraTags"].includes("featured")){
                countF++;

                if(!lastThreeMonths.includes(this.jsonData["Pieces"][i]["monthCreated"])){
                  countFMonthBefore++;
                }

                if(featuredData[this.jsonData["Pieces"][i]["monthCreated"]]){
                  featuredData[this.jsonData["Pieces"][i]["monthCreated"]] += 1;
                }else{
                  featuredData[this.jsonData["Pieces"][i]["monthCreated"]] = 1;
                }
              }
              if(this.jsonData["Pieces"][i]["extraTags"].includes("extended")){
                countExtended++;

                if(!lastThreeMonths.includes(this.jsonData["Pieces"][i]["monthCreated"])){
                  countExtendedMonthBefore++;
                }

                if(extendedData[this.jsonData["Pieces"][i]["monthCreated"]]){
                  extendedData[this.jsonData["Pieces"][i]["monthCreated"]] += 1;
                }else{
                  extendedData[this.jsonData["Pieces"][i]["monthCreated"]] = 1;
                }
              }

              if(!lastThreeMonths.includes(this.jsonData["Pieces"][i]["monthCreated"])){
                  countMonthBefore++;
              }
              count++;
            }
          }
          this.countPublished = count;
          this.countFPlus = countFPlus;
          this.countF = countF;
          this.countExtended = countExtended;

          this.countPublishedMonthBefore = countMonthBefore;
          this.countFPlusMonthBefore = countFPlusMonthBefore;
          this.countFMonthBefore = countFMonthBefore;
          this.countExtendedMonthBefore = countExtendedMonthBefore;

          this.numberItemsFeaturedPMonthly = {
              labels: monthNames,
              datasets: [
                {
                  label: "Count - " + this.selectedTeamOne,
                  data: Object.values(featuredPlusData),
                  borderColor: "#ff7777",
                  backgroundColor: ['#ff7777'],
                  hoverBackgroundColor: "#f33636",
                  color: "white",

                }
              ],
            }

          this.numberItemsFeaturedMonthly = {
              labels: monthNames,
              datasets: [
                {
                  label: "Count - " + this.selectedTeamOne,
                  data: Object.values(featuredData),
                  borderColor: "#ff7777",
                  backgroundColor: ['#ff7777'],
                  hoverBackgroundColor: "#f33636",
                  color: "white",

                }
              ],
            }

          this.numberItemsExtendedMonthly = {
              labels: monthNames,
              datasets: [
                {
                  label: "Count - " + this.selectedTeamOne,
                  data: Object.values(extendedData),
                  borderColor: "#ff7777",
                  backgroundColor: ['#ff7777'],
                  hoverBackgroundColor: "#f33636",
                  color: "white",

                }
              ],
            }


          this.monthDataPublishedChart = {
            labels: monthNames,
            datasets: [
              {
                label: "Catalog size of " + this.selectedTeamOne,
                data: monthData,
                borderColor: "#ff7777",
                backgroundColor: ['#ff7777'],
                hoverBackgroundColor: "#f33636",
                yAxisID: 'y',
                trendlineLinear: {
                  style: "#f19d9d",
                  lineStyle: "dotted",
                  width: 2,
                  projection: false
                }
              },
              {
                label: "Global catalog size",
                data: monthNamesCountPurchasable,
                borderColor: "#6e6e6e",
                backgroundColor: ['#6e6e6e'],
                hoverBackgroundColor: "#4b4a4a",
                yAxisID: 'y2',
                trendlineLinear: {
                  style: "#888888",
                  lineStyle: "dotted",
                  width: 2,
                  projection: false
                }
              },
            ],
          };

          if(monthDataTwo.length !== 0){
            this.monthDataPublishedChart.datasets.push({
              label: "Catalog size of " + this.selectedTeamTwo,
              data: monthDataTwo,
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
              yAxisID: 'y',
              trendlineLinear: {
                style: "#3e95cd",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            })
          }


          let monthDataRanking = [];
          let monthDataRankingTwo = [];
          let monthNamesRanking = [];

          for (let i = 0; i < Object.keys(this.jsonData["Stats"]).length; i++) {
            monthNamesRanking.push(Object.keys(this.jsonData["Stats"])[i])
            if (Object.values(this.jsonData["Stats"])[i]["partnerRankData"][this.selectedTeamOne]) {
              monthDataRanking.push(Object.values(this.jsonData["Stats"])[i]["partnerRankData"][this.selectedTeamOne]);
            }else if(this.partnerList.includes(this.selectedTeamOne)){
              //it means we don't have data for this month but it exists
              monthDataRanking.push(Object.entries(Object.values(this.jsonData["Stats"])[i]["partnerRankData"]).sort((x, y) => y[1] - x[1])[0][1]);
            }
            if (Object.values(this.jsonData["Stats"])[i]["partnerRankData"][this.selectedTeamTwo]) {
              monthDataRankingTwo.push(Object.values(this.jsonData["Stats"])[i]["partnerRankData"][this.selectedTeamTwo]);
            }else if(this.partnerList.includes(this.selectedTeamTwo)){
              //it means we don't have data for this month but it exists
              monthDataRankingTwo.push(Object.entries(Object.values(this.jsonData["Stats"])[i]["partnerRankData"]).sort((x, y) => y[1] - x[1])[0][1]);
            }
          }

          this.monthDataRankingChart = {
            labels: monthNamesRanking,
            datasets: [
              {
                label: "Rank of " + this.selectedTeamOne,
                data: monthDataRanking,
                borderColor: "#ff7777",
                backgroundColor: ['#ff7777'],
                hoverBackgroundColor: "#f33636",
                trendlineLinear: {
                  style: "#f19d9d",
                  lineStyle: "dotted",
                  width: 2,
                  projection: false
                }
              }
            ],
          };

          if(monthDataRankingTwo.length !== 0){
            this.monthDataRankingChart.datasets.push({
              label: "Rank of " + this.selectedTeamTwo,
              data: monthDataRankingTwo,
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
              trendlineLinear: {
                style: "#3e95cd",
                lineStyle: "dotted",
                width: 2,
                projection: false
              }
            })
          }

          if(this.selectedTeamTwo !== "" && this.partnerList.includes(this.selectedTeamTwo)) {
            let count2 = 0;
            let countF2 = 0;
            let countFPlus2 = 0;
            let countExtended2 = 0;

            let count2MonthBefore = 0;
            let countF2MonthBefore = 0;
            let countFPlus2MonthBefore = 0;
            let countExtended2MonthBefore = 0;
            

            let rarityData = {
              "Common": 0,
              "Uncommon": 0,
              "Rare": 0,
              "Epic": 0,
              "Legendary": 0
            };
            let rarityDataTwo = {
              "Common": 0,
              "Uncommon": 0,
              "Rare": 0,
              "Epic": 0,
              "Legendary": 0
            };

            let categoryData = {}
            let categoryDataTwo = {}

            let wordCounts = {}
            let wordCountsTwo = {}


            for (let i in this.jsonData["Pieces"]) {
              if (this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] === this.selectedTeamTwo) {
                let words = this.jsonData["Pieces"][i].title.neutral.split(" ");

                for(var i2 = 0; i2 < words.length; i2++)
                    wordCountsTwo[words[i2]] = (wordCountsTwo[words[i2]] || 0) + 1;

                rarityDataTwo[this.jsonData["Pieces"][i]["displayProperties"]["rarity"]]++;

                if(!categoryDataTwo[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]]){
                  categoryDataTwo[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]] = 1;
                }else{
                  categoryDataTwo[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]]++;
                }

                if (this.jsonData["Pieces"][i]["extraTags"].includes("featured+")) {
                  countFPlus2++;

                  if(!lastThreeMonths.includes(this.jsonData["Pieces"][i]["monthCreated"])){
                    countFPlus2MonthBefore++;
                  }
                  if(featuredPlusDataTwo[this.jsonData["Pieces"][i]["monthCreated"]]){
                    featuredPlusDataTwo[this.jsonData["Pieces"][i]["monthCreated"]] += 1;
                  }else{
                    featuredPlusDataTwo[this.jsonData["Pieces"][i]["monthCreated"]] = 1;
                  }
                }
                if (this.jsonData["Pieces"][i]["extraTags"].includes("featured")) {
                  countF2++;

                  if(!lastThreeMonths.includes(this.jsonData["Pieces"][i]["monthCreated"])){
                    countF2MonthBefore++;
                  }

                  if(featuredDataTwo[this.jsonData["Pieces"][i]["monthCreated"]]){
                    featuredDataTwo[this.jsonData["Pieces"][i]["monthCreated"]] += 1;
                  }else{
                    featuredDataTwo[this.jsonData["Pieces"][i]["monthCreated"]] = 1;
                  }
                }
                if (this.jsonData["Pieces"][i]["extraTags"].includes("extended")) {
                  countExtended2++;

                  if(!lastThreeMonths.includes(this.jsonData["Pieces"][i]["monthCreated"])){
                    countExtended2MonthBefore++;
                  }

                  if(extendedDataTwo[this.jsonData["Pieces"][i]["monthCreated"]]){
                    extendedDataTwo[this.jsonData["Pieces"][i]["monthCreated"]] += 1;
                  }else{
                    extendedDataTwo[this.jsonData["Pieces"][i]["monthCreated"]] = 1;
                  }
                }

                count2++;
                if(!lastThreeMonths.includes(this.jsonData["Pieces"][i]["monthCreated"])){
                    count2MonthBefore++;
                }
              }

              if (this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] === this.selectedTeamOne) {

                let words = this.jsonData["Pieces"][i].title.neutral.split(" ");

                for(var i3 = 0; i3 < words.length; i3++)
                    wordCounts[words[i3]] = (wordCounts[words[i3]] || 0) + 1;

                rarityData[this.jsonData["Pieces"][i]["displayProperties"]["rarity"]]++;

                if(!categoryData[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]]){
                  categoryData[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]] = 1;
                }else{
                  categoryData[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]]++;
                }
              }
            }

            this.numberItemsFeaturedPMonthly.datasets.push({
              label: "Count - " + this.selectedTeamTwo,
              data: Object.values(featuredPlusDataTwo),
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
            })

            this.numberItemsFeaturedMonthly.datasets.push({
              label: "Count - " + this.selectedTeamTwo,
              data: Object.values(featuredDataTwo),
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
            })

            this.numberItemsExtendedMonthly.datasets.push({
              label: "Count - " + this.selectedTeamTwo,
              data: Object.values(extendedDataTwo),
              borderColor: "#2c69e1",
              backgroundColor: ['#2c69e1'],
              hoverBackgroundColor: "#135ae0",
            })

            var items = Object.keys(wordCounts).map(function(key) {
              return [key, wordCounts[key]];
            });

            // Sort the array based on the second element
            items.sort(function(first, second) {
              return second[1] - first[1];
            });

            var itemsTwo = Object.keys(wordCountsTwo).map(function(key) {
              return [key, wordCountsTwo[key]];
            });

            // Sort the array based on the second element
            itemsTwo.sort(function(first, second) {
              return second[1] - first[1];
            });

            this.countPublished2 = count2;
            this.countFPlus2 = countFPlus2;
            this.countF2 = countF2;
            this.countExtended2 = countExtended2;

            this.countPublished2MonthBefore = count2MonthBefore;
            this.countFPlus2MonthBefore = countFPlus2MonthBefore;
            this.countF2MonthBefore = countF2MonthBefore;
            this.countExtended2MonthBefore = countExtended2MonthBefore;


            this.numberItemsPurchasableChart = {
              labels: [Object.keys(this.jsonData["Stats"])[Object.keys(this.jsonData["Stats"]).length - 1]],
              datasets: [
                {
                  label: this.selectedTeamOne,
                  data: [this.countPurchasable],
                  borderColor: "#ff7777",
                  backgroundColor: ['#ff7777'],
                  hoverBackgroundColor: "#f33636",
                  color: "white",

                },
                {
                  label: this.selectedTeamTwo,
                  data: [this.countPurchasable2],
                  borderColor: "#2c69e1",
                  backgroundColor: ['#2c69e1'],
                  hoverBackgroundColor: "#135ae0",
                  color: "white",
                },
              ],
            }


            this.numberItemsPublishedChart = {
              labels: ["Total"],
              datasets: [
                {
                  label: this.selectedTeamOne,
                  data: [this.countPublished],
                  borderColor: "#ff7777",
                  backgroundColor: ['#ff7777'],
                  hoverBackgroundColor: "#f33636",
                  color: "white",

                },
                {
                  label: this.selectedTeamTwo,
                  data: [this.countPublished2],
                  borderColor: "#2c69e1",
                  backgroundColor: ['#2c69e1'],
                  hoverBackgroundColor: "#135ae0",
                  color: "white",
                },
              ],
            }

            this.numberItemsFeaturedPChart = {
              labels: ["Total"],
              datasets: [
                {
                  label: this.selectedTeamOne + " (" + Math.round(this.countFPlus / this.countPublished * 100) + "% rate)",
                  data: [this.countFPlus],
                  borderColor: "#ff7777",
                  backgroundColor: ['#ff7777'],
                  hoverBackgroundColor: "#f33636",
                  color: "white",

                },
                {
                  label: this.selectedTeamTwo + " (" + Math.round(this.countFPlus2 / this.countPublished2 * 100) + "% rate)",
                  data: [this.countFPlus2],
                  borderColor: "#2c69e1",
                  backgroundColor: ['#2c69e1'],
                  hoverBackgroundColor: "#135ae0",
                  color: "white",
                },
              ],
            }

            this.numberItemsFeaturedChart = {
              labels: ["Total"],
              datasets: [
                {
                  label: this.selectedTeamOne + " (" + Math.round(this.countF / this.countPublished * 100) + "% rate)",
                  data: [this.countF],
                  borderColor: "#ff7777",
                  backgroundColor: ['#ff7777'],
                  hoverBackgroundColor: "#f33636",
                  color: "white",

                },
                {
                  label: this.selectedTeamTwo + " (" + Math.round(this.countF2 / this.countPublished2 * 100) + "% rate)",
                  data: [this.countF2],
                  borderColor: "#2c69e1",
                  backgroundColor: ['#2c69e1'],
                  hoverBackgroundColor: "#135ae0",
                  color: "white",
                },
              ],
            }

            this.numberItemsExtendedChart = {
              labels: ["Total"],
              datasets: [
                {
                  label: this.selectedTeamOne + " (" + Math.round(this.countExtended / this.countPublished * 100) + "% rate)",
                  data: [this.countExtended],
                  borderColor: "#ff7777",
                  backgroundColor: ['#ff7777'],
                  hoverBackgroundColor: "#f33636",
                  color: "white",

                },
                {
                  label: this.selectedTeamTwo + " (" + Math.round(this.countExtended2 / this.countPublished2 * 100) + "% rate)",
                  data: [this.countExtended2],
                  borderColor: "#2c69e1",
                  backgroundColor: ['#2c69e1'],
                  hoverBackgroundColor: "#135ae0",
                  color: "white",
                },
              ],
            }

            this.rarityItemDistribution = {
              labels: [this.selectedTeamOne, this.selectedTeamTwo],
              datasets: [
                {
                  label: "Legendary",
                  data:  [Math.round(rarityData["Legendary"] / this.countPublished * 100), Math.round(rarityDataTwo["Legendary"] / this.countPublished2 * 100)],
                  borderColor: "#ab7b56",
                  backgroundColor: ['#ab7b56'],
                  hoverBackgroundColor: "#835e43",
                },
                {
                  label: "Epic",
                  data:  [Math.round(rarityData["Epic"] / this.countPublished * 100), Math.round(rarityDataTwo["Epic"] / this.countPublished2 * 100)],
                  borderColor: "#AA3CC6",
                  backgroundColor: ['#AA3CC6'],
                  hoverBackgroundColor: "#1d0e20",
                },
                {
                  label: "Rare",
                  data:  [Math.round(rarityData["Rare"] / this.countPublished * 100), Math.round(rarityDataTwo["Rare"] / this.countPublished2 * 100)],
                  borderColor: "#1a409b",
                  backgroundColor: ['#1a409b'],
                  hoverBackgroundColor: "#0a193d",
                },
                {
                  label: "Uncommon",
                  data:  [Math.round(rarityData["Uncommon"] / this.countPublished * 100), Math.round(rarityDataTwo["Uncommon"] / this.countPublished2 * 100)],
                  borderColor: "#6e6e6e",
                  backgroundColor: ['#6e6e6e'],
                  hoverBackgroundColor: "#4b4b4b",
                },
                {
                  label: "Common",
                  data: [Math.round(rarityData["Common"] / this.countPublished * 100), Math.round(rarityDataTwo["Common"] / this.countPublished2 * 100)],
                  borderColor: "#214029",
                  backgroundColor: ['#214029'],
                  hoverBackgroundColor: "#132517",
                }
              ],
            }

            let itemNames = [];
            let itemCounts = [];
            for(let i in categoryData){
              itemNames.push(this.conversionList[i])
              itemCounts.push(categoryData[i])
            }

            this.teamOneItemDistribution = {
              labels: itemNames,
              datasets: [
                {
                  label: "Count",
                  data: itemCounts,
                  borderColor: ["#ab5e6b",
"#67bf39",
"#c148d6",
"#5baf67",
"#6f54d9",
"#a5a645",
"#647cd6",
"#d79a38",
"#a768b9",
"#5f7238",
"#d249a1",
"#69b2a1",
"#db4433",
"#73a8cd",
"#d5456d",
"#5e6f6a",
"#b96637",
"#656a95",
"#bd9679",
"#cb9bbe"],
                  backgroundColor: ["#ab5e6b",
"#67bf39",
"#c148d6",
"#5baf67",
"#6f54d9",
"#a5a645",
"#647cd6",
"#d79a38",
"#a768b9",
"#5f7238",
"#d249a1",
"#69b2a1",
"#db4433",
"#73a8cd",
"#d5456d",
"#5e6f6a",
"#b96637",
"#656a95",
"#bd9679",
"#cb9bbe"],
                  hoverBackgroundColor: ["#ab5e6b",
"#67bf39",
"#c148d6",
"#5baf67",
"#6f54d9",
"#a5a645",
"#647cd6",
"#d79a38",
"#a768b9",
"#5f7238",
"#d249a1",
"#69b2a1",
"#db4433",
"#73a8cd",
"#d5456d",
"#5e6f6a",
"#b96637",
"#656a95",
"#bd9679",
"#cb9bbe"],
                  color: "white",

                }
              ],
            }

            let itemNamesTwo = [];
            let itemCountsTwo = [];
            for(let i in categoryData){
              itemNamesTwo.push(this.conversionList[i])
              itemCountsTwo.push(categoryDataTwo[i])
            }
            for(let i in categoryDataTwo){
              if(!itemNamesTwo.includes(this.conversionList[i])){
                itemNamesTwo.push(this.conversionList[i])
                itemCountsTwo.push(categoryDataTwo[i])
              }
            }

            this.teamTwoItemDistribution = {
              labels: itemNamesTwo,
              datasets: [
                {
                  label: "Count",
                  data: itemCountsTwo,
                  borderColor: ["#ab5e6b",
"#67bf39",
"#c148d6",
"#5baf67",
"#6f54d9",
"#a5a645",
"#647cd6",
"#d79a38",
"#a768b9",
"#5f7238",
"#d249a1",
"#69b2a1",
"#db4433",
"#73a8cd",
"#d5456d",
"#5e6f6a",
"#b96637",
"#656a95",
"#bd9679",
"#cb9bbe"],
                  backgroundColor: ["#ab5e6b",
"#67bf39",
"#c148d6",
"#5baf67",
"#6f54d9",
"#a5a645",
"#647cd6",
"#d79a38",
"#a768b9",
"#5f7238",
"#d249a1",
"#69b2a1",
"#db4433",
"#73a8cd",
"#d5456d",
"#5e6f6a",
"#b96637",
"#656a95",
"#bd9679",
"#cb9bbe"],
                  hoverBackgroundColor: ["#ab5e6b",
"#67bf39",
"#c148d6",
"#5baf67",
"#6f54d9",
"#a5a645",
"#647cd6",
"#d79a38",
"#a768b9",
"#5f7238",
"#d249a1",
"#69b2a1",
"#db4433",
"#73a8cd",
"#d5456d",
"#5e6f6a",
"#b96637",
"#656a95",
"#bd9679",
"#cb9bbe"],
                  color: "white",

                }
              ],
            }

            this.teamOneWordsDistribution = {
              labels: items.slice(0, 10).map((element) => element[0]),
              datasets: [
                {
                  label: "Count",
                  data: items.slice(0, 10).map((element) => element[1]),
                  borderColor: ["#ff7777"],
                  backgroundColor: ["#ff7777"],
                  hoverBackgroundColor: ["#ff7777"],
                  color: "white",

                }
              ],
            }

            this.teamTwoWordsDistribution = {
              labels: itemsTwo.slice(0, 10).map((element) => element[0]),
              datasets: [
                {
                  label: "Count",
                  data: itemsTwo.slice(0, 10).map((element) => element[1]),
                  borderColor: ["#2c69e1"],
                  backgroundColor: ["#2c69e1"],
                  hoverBackgroundColor: ["#2c69e1"],
                  color: "white",

                }
              ],
            }

          }
          NProgress.done()
        }
      }
    },
    getOptionData(title, chartBar = false) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            reverse: true,
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          },
          x: {
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: "#9ca3af",
            },
            position: 'top'
          },
          title: {
            display: true,
            color: "#e5e0e0",
            text: title
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                if (context.parsed.y !== null && chartBar) {
                  label += ': ' + context.parsed.y + '%';
                } else {
                  label += ': ' + context.parsed.y;
                }
                return label;
              }
            }
          }
        }
      }
    },
    getOptionDataDefault(title, chartBar = false){
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          },
          x: {
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: "#9ca3af",
            },
            position: 'top'
          },
          title: {
            display: true,
            color: "#e5e0e0",
            text: title
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (context.parsed.y !== null && chartBar) {
                  label += ': ' +context.parsed.y + '%';
                }else{
                  label += ': ' + context.parsed.y;
                }
                return label;
              }
            }
          }
        }
      }
    },
    getOptionDataDoughnut(title){
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              color: "#9ca3af",
            },
            position: 'top'
          },
          title: {
            display: true,
            color: "#e5e0e0",
            text: title
          }
        }
      }
    },
    getOptionDataMultiAxis(title, chartBar = false){
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            position: "left",
            beginAtZero: true,
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          },
          y2: {
            position: "right",
            beginAtZero: true,
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          },
          x: {
            stacked: chartBar,
            grid: {
              display: false,
              color: "#FFFFFF"
            },
            ticks: {
              color: "#9ca3af",
              beginAtZero: true
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: "#9ca3af",
            },
            position: 'top'
          },
          title: {
            display: true,
            color: "#e5e0e0",
            text: title
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (context.parsed.y !== null && chartBar) {
                  label += ': ' +context.parsed.y + '%';
                }else{
                  label += ': ' + context.parsed.y;
                }
                return label;
              }
            }
          }
        }
      }
    }
  }
}
</script>
