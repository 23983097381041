<template>
  <div v-bind="$attrs" class="container is-max-widescreen" style="">
    <div class="columns is-multiline is-centered is-marginless">
      <nav class="breadcrumb has-dot-separator column is-full" aria-label="breadcrumbs">
        <ul>
          <li class=""><a v-on:click="navigateTo('')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-home" aria-hidden="true"></i>
            </span>
            Home
          </a></li>
          <li><a v-on:click="navigateTo('new')">
            <span class="icon is-small">
              <i class="fas fa-sync" aria-hidden="true"></i>
            </span>
            New
          </a></li>
          <li class=""><a v-on:click="navigateTo('featured')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-fire" aria-hidden="true"></i>
            </span>
            Featured
          </a></li>
          <li class=""><a v-on:click="navigateTo('stats')" aria-current="page">
            <span class="icon is-small">
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </span>
            Stats
          </a></li>
        </ul>
      </nav>

      <div class="content" v-if="$auth.isAuthenticated.value">
        <h2 class="titleBox has-text-white-ter" > Settings.</h2>
      </div>
      <div v-else class="column-error">
        <!-- <p class="has-text-white">Please login to see this page (access restricted to <strong>Premium</strong> users only). </p> -->
        <p class="has-text-white">Please login to see this page. </p>
      </div>

    </div>
  </div>
  <br>
</template>
<script>
import {useMeta} from "vue-meta";

export default {
  name: 'Content',
  props: {
    isPremiumAccount: Boolean,
    jsonData: Object
  },
  data(){
    return{
      jsonToRender: [],
      found: false
    }
  },
  setup () {
    useMeta({
      title: 'Settings',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  components: {

  },
  mounted() {
    this.checkRoute();
  },
  methods: {
    navigateTo(path){
      this.$router.push({ path: "/" + path  })
    },
    checkRoute(){
      let teamName = this.$route.params.teamName
      var count = 0;

      for(let i in this.jsonData["Pieces"]){
        if(this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] === teamName){
          count++;
          this.jsonToRender.push(this.jsonData["Pieces"][i])
        }
      }
      this.found = count > 0;
    }
  }
}
</script>
<style scoped>
.column{
  text-align: center;
}
</style>
