import { createWebHistory, createRouter } from "vue-router";
import ProductsView from "./../views/ProductsView.vue";
import ContentView from "./../views/ContentView.vue";
import FeaturedView from "../views/FeaturedView.vue";
import NewItemsView from "./../views/NewItemsView.vue";
import StatsView from "./../views/StatsView.vue";
import SettingsView from "./../views/SettingsView.vue";
import ComparatorView from "../views/ComparatorView";
import ComparatorViewDev from "../views/ComparatorViewDev";
import MisteView from "../views/MisteView";
import MaxView from "../views/MaxView";
import MonitorView from "../views/MonitorView";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "Default Page",
    component: NewItemsView
  },
  {
    path: "/content/:pieceId",
    name: "Content Page",
    component: ContentView,
  },
  {
    path: "/settings",
    name: "Settings Page",
    component: SettingsView,
  },
  {
    path: "/featured",
    name: "Featured Page",
    component: FeaturedView,
  },
  {
    path: "/products/:pathMatch(.*)*",
    name: "Products Page",
    component: ProductsView,
  },
  {
    path: "/stats",
    name: "Statistics Page",
    component: StatsView,
  },
  {
    path: "/comparator",
    name: "Comparator Page",
    component: ComparatorView,
  },
  {
    path: "/miste/:pathMatch(.*)*",
    name: "Miste Page",
    component: MisteView,
  },
  {
    path: "/max/:pathMatch(.*)*",
    name: "Max Page",
    component: MaxView,
  },
  {
    path: "/monitor",
    name: "Monitor Page",
    component: MonitorView,
  },
  {
    path: "/comparatorDev",
    name: "Comparator Dev Page",
    component: ComparatorViewDev,
  }
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})


export default router;