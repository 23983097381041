<template xmlns:cursor="http://www.w3.org/1999/xhtml">
   <div v-bind="$attrs" class="container is-max-widescreen">
      <div class="columns is-multiline is-centered is-marginless">
        <nav class="breadcrumb has-dot-separator column is-full" aria-label="breadcrumbs">
          <ul>
            <li class=""><a href="#" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-home" aria-hidden="true"></i>
            </span>
            Home
            </a></li>
            <li><a v-on:click="navigateTo('products')">
            <span class="icon is-small">
              <i class="fas fa-list" aria-hidden="true"></i>
            </span>
            Products
            </a></li>
            <li class=""><a v-on:click="navigateTo('featured')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-fire" aria-hidden="true"></i>
            </span>
              Featured
            </a></li>
            <li class=""><a v-on:click="navigateTo('stats')" aria-current="page">
            <span class="icon is-small">
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </span>
              Stats
            </a></li>
            <li class=""><a v-on:click="navigateTo('comparator')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-balance-scale-left" aria-hidden="true"></i>
            </span>
              Comparator
            </a></li>
            <li class=""><a v-bind:href="'https://patreon.com/PersonaExplorer'" target="_blank" aria-current="page">
              <span class="icon is-small">
                  <i class="fas fa-star"></i>
              </span>
            Premium Subscription
            </a></li>
          </ul>
        </nav>

        <div>
            <h1 class="titleBox has-text-white-ter has-text-centered has-text-weight-bold"> {{numberOfPersonasReleased}} Items released in {{currentMonth}}.</h1 >
            <h5 class="has-text-white is-italic">New Persona Items are released every first of the month.</h5>
        </div>

        <div class="column is-full">
          <span class="select selector">
            <select @change="onCategoryChange($event)" v-model="categorySelected" class="custom-select">
              <option cursor:pointer value="Top">Top ({{piecesTypesCount["Top"]}})</option>
              <option cursor:pointer value="Back">Back ({{piecesTypesCount["Back"]}})</option>
              <option cursor:pointer value="Bottom">Bottom ({{piecesTypesCount["Bottom"]}})</option>
              <option cursor:pointer value="Outerwear">Outerwear ({{piecesTypesCount["Outerwear"]}})</option>
              <option cursor:pointer value="Hood">Hood ({{piecesTypesCount["Hood"]}})</option>
              <option cursor:pointer value="Dress">Dress ({{piecesTypesCount["Dress"]}})</option>
              <option cursor:pointer value="Headwear">Headwear ({{piecesTypesCount["Headwear"]}})</option>
              <option cursor:pointer value="Gloves">Gloves ({{piecesTypesCount["Gloves"]}})</option>
              <option cursor:pointer value="Hand">Hand ({{piecesTypesCount["Hand"]}})</option>
              <option cursor:pointer value="Arms">Arms ({{piecesTypesCount["Arms"]}})</option>
              <option cursor:pointer value="Feet">Feet ({{piecesTypesCount["Feet"]}})</option>
              <option cursor:pointer value="Legs">Legs ({{piecesTypesCount["Legs"]}})</option>
              <option cursor:pointer value="High Pants">High Pants ({{piecesTypesCount["High Pants"]}})</option>
              <option cursor:pointer value="Face Accessory">Face Accessory ({{piecesTypesCount["Face Accessory"]}})</option>
              <option cursor:pointer value="Facial Hair">Facial Hair ({{piecesTypesCount["Facial Hair"]}})</option>
              <option cursor:pointer value="Hair">Hair ({{piecesTypesCount["Hair"]}})</option>
              <option cursor:pointer value="Back Accessory">Back Accessory ({{piecesTypesCount["Back Accessory"]}})</option>
              <option cursor:pointer value="Emote">Emote ({{piecesTypesCount["Emote"]}})</option>
              <option cursor:pointer value="Mouth">Mouth ({{piecesTypesCount["Mouth"]}})</option>
              <option cursor:pointer value="Skin">Skin ({{piecesTypesCount["Skin"]}})</option>
              <option cursor:pointer value="Eyes">Eyes ({{piecesTypesCount["Eyes"]}})</option>
              <option cursor:pointer value="Capes">Capes ({{piecesTypesCount["Capes"]}})</option>
            </select>
          </span>
        </div>

          <div class="column is-full has-text-white" id="results">
            <p>{{numberOfResults}} pieces found.</p>
          </div>
        
        <div class="column is-one-fifth" v-for="data in jsonToRender" :key="data">
              <PersonaItemCard :pieceOfContent="data" :isPremium=isPremiumAccount :clickable="false"/>
        </div>
    </div>    
  </div>
  <br>
</template>
<script>
import PersonaItemCard from '../components/PersonaItemCard.vue'
import NProgress from "nprogress";
import {useMeta} from "vue-meta";

export default {
  name: 'NewItems',
  props: {
    isPremiumAccount: Boolean,
    jsonData: Object
  },
  data(){
    return{
      categorySelected: "Top",
      jsonToRender: {},
      numberOfResults: {},
      piecesTypesCount: {},
      numberOfPersonasReleased: 0,
      currentMonth: null,
      dataToRender: [],
      conversionList: [],
      monthNames: []
    }
  },
  created() {
    const conversionList = {
      "persona_top": "Top",
      "persona_back": "Back",
      "persona_bottom": "Bottom",
      "persona_outerwear": "Outerwear",
      "persona_gloves": "Gloves",
      "persona_face_accessory": "Face Accessory",
      "persona_back_accessory": "Back Accessory",
      "persona_hand": "Hand",
      "persona_head": "Headwear",
      "persona_facial_hair": "Facial Hair",
      "persona_emote": "Emote",
      "persona_feet": "Feet",
      "persona_mouth": "Mouth",
      "persona_legs": "Legs",
      "persona_arms": "Arms",
      "persona_hood": "Hood",
      "persona_dress": "Dress",
      "persona_high_pants": "High Pants",
      "persona_hair": "Hair",
      "persona_skin": "Skin",
      "persona_eyes": "Eyes",
      "persona_capes": "Capes"
    };
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    let currentMonth =  monthNames[new Date().getMonth()] + " " + new Date().getFullYear()
    this.currentMonth = currentMonth;

    for(let typePiece in conversionList){
      this.dataToRender[conversionList[typePiece]] = []
      this.piecesTypesCount[conversionList[typePiece]] = 0
    }

    let numberOfPersonasReleased = 0
    for(let i in this.jsonData["Pieces"]){
      if(this.jsonData["Pieces"][i]["monthCreated"] === currentMonth){
        numberOfPersonasReleased += 1
        this.dataToRender[conversionList[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]]].push(this.jsonData["Pieces"][i]);
        this.piecesTypesCount[conversionList[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]]] += 1;
      }
    }
    this.conversionList = conversionList;
    this.numberOfPersonasReleased = numberOfPersonasReleased;
    this.jsonToRender = this.dataToRender["Top"];
    this.numberOfResults = this.piecesTypesCount["Top"];
  },
  setup () {
    useMeta({
      title: 'Home',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  components: {
    PersonaItemCard
  },
  watch: {
    isPremiumAccount: function(newVal) {
      if(newVal){
        this.updatePage()
      }
    }
  },
  methods: {
    navigateTo(path){
      this.$router.push({ path: "/" + path  })
    },
    onCategoryChange(){
      NProgress.start();
      this.jsonToRender = this.dataToRender[this.categorySelected]
      this.numberOfResults = this.piecesTypesCount[this.categorySelected]
      NProgress.done();
    },
    updatePage(){
      this.dataToRender = []

      for(let typePiece in this.conversionList){
          this.dataToRender[this.conversionList[typePiece]]= []
      }

      for(let i in this.jsonData["Pieces"]){
        if(this.jsonData["Pieces"][i]["monthCreated"] === this.currentMonth){
          this.dataToRender[this.conversionList[this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]]].push(this.jsonData["Pieces"][i])
        }
      }
    }
  }
}
</script>
