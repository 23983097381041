<template>
   <div v-bind="$attrs" class="container is-max-widescreen" style="">
      <div class="columns is-multiline is-centered is-marginless">
        <nav class="breadcrumb has-dot-separator column is-full" aria-label="breadcrumbs">
          <ul>
            <li class=""><a v-on:click="navigateTo('')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-home" aria-hidden="true"></i>
            </span>
              Home
            </a></li>
            <li><a v-on:click="navigateTo('products')">
            <span class="icon is-small">
              <i class="fas fa-list" aria-hidden="true"></i>
            </span>
              Products
            </a></li>
            <li class=""><a v-on:click="navigateTo('featured')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-fire" aria-hidden="true"></i>
            </span>
              Featured
            </a></li>
            <li class=""><a v-on:click="navigateTo('stats')" aria-current="page">
            <span class="icon is-small">
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </span>
              Stats
            </a></li>
            <li class=""><a v-on:click="navigateTo('comparator')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-balance-scale-left" aria-hidden="true"></i>
            </span>
              Comparator
            </a></li>
            <li class=""><a v-bind:href="'https://patreon.com/PersonaExplorer'" target="_blank" aria-current="page">
              <span class="icon is-small">
                  <i class="fas fa-star"></i>
              </span>
            Premium Subscription
            </a></li>
          </ul>
        </nav>

        <div v-if="found" class="column has-text-centered">
              <PersonaContentView :pieceOfContent="jsonToRender" :isPremium="isPremiumAccount"/>
        </div>
        <div class="column has-text-centered" v-else>
          <h1 class="has-text-white"> Could not find a Persona with id '{{$route.params.pieceId}}', please retry. </h1>
        </div>
    </div>
  </div>
  <br>
</template>
<script>
import PersonaContentView from '../components/PersonaContentView.vue'
import {useMeta} from "vue-meta";

export default {
  name: 'Content',
  props: {
    isPremiumAccount: Boolean,
    jsonData: Object
  },
  data(){
    return{
      jsonToRender: {},
      found: false
    }
  },
  setup () {
    useMeta({
      title: 'Content',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  components: {
    PersonaContentView
  },
  mounted() {
    this.checkRoute();
  },
  methods: {
    navigateTo(path){
      this.$router.push({ path: "/" + path  })
    },
    checkRoute(){
      let pieceId = this.$route.params.pieceId

      if(this.jsonData["Pieces"][pieceId]){
        this.found = true
        this.jsonToRender = this.jsonData["Pieces"][pieceId]
      }else{
        this.found = false
        this.jsonToRender = {}
      }
    }
  }
}
</script>
<style scoped>
</style>
