// db.js
import Dexie from 'dexie';

// Initialize Dexie database
const db = new Dexie('MyAppDatabase');

// Define the database schema
db.version(1).stores({
  dataStore: 'id', // 'id' is the primary key
});

// Function to store data
function storeData(key, data) {
  return db.dataStore.put({ id: key, data });
}

// Function to fetch data
function fetchData(key) {
  return db.dataStore.get(key)
    .then(item => {
      if (!item) throw new Error("Data not found");
      return item.data;
    });
}

// Export the functions for use in other parts of the application
export { storeData, fetchData };