<template xmlns:cursor="http://www.w3.org/1999/xhtml">
  <div v-bind="$attrs" class="container is-max-widescreen">
    <div class="columns is-multiline is-centered is-marginless">
      <nav class="breadcrumb has-dot-separator column is-full" aria-label="breadcrumbs">
        <ul>
          <li class=""><a v-on:click="navigateTo('')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-home" aria-hidden="true"></i>
            </span>
            Home
          </a></li>
          <li><a href="#">
            <span class="icon is-small">
              <i class="fas fa-list" aria-hidden="true"></i>
            </span>
            Products
          </a></li>
          <li class=""><a v-on:click="navigateTo('featured')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-fire" aria-hidden="true"></i>
            </span>
            Featured
          </a></li>
          <li class=""><a v-on:click="navigateTo('stats')" aria-current="page">
            <span class="icon is-small">
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </span>
            Stats
          </a></li>
          <li class=""><a v-on:click="navigateTo('comparator')" aria-current="page">
            <span class="icon is-small">
              <i class="fas fa-balance-scale-left" aria-hidden="true"></i>
            </span>
            Comparator
          </a></li>
          <li class=""><a v-bind:href="'https://patreon.com/PersonaExplorer'" target="_blank" aria-current="page">
              <span class="icon is-small">
                  <i class="fas fa-star"></i>
              </span>
            Premium Subscription
          </a></li>
        </ul>
      </nav>

      <div class="column is-two-fifths">
        <label class="label has-text-white">Search</label>
        <div class="field">
          <p class="control is-expanded has-icons-right has-text-white">
            <input class="input has-text-white" type="search" placeholder="Enter Item Name..."
                   v-debounce:500ms="searchHandler" v-model="searchTerm"/>
          </p>
        </div>
      </div>


      <div class="column is-one-quarter">
        <div v-if="isPremiumAccount" class="field">
          <label class="label has-text-white">Order by</label>
          <div class="control">
            
                
                <span class="select">
                  <select @change="onOrderByChange($event)" v-model="orderBySelected">
                    <option cursor:pointer value="released">Release date</option>
                    <option cursor:pointer value="stayed">Run for</option>
                    <option cursor:pointer value="name">Name</option>
                    <option cursor:pointer value="creator">Creator</option>
                    <option cursor:pointer value="rarity">Rarity</option>
                    <option cursor:pointer value="type">Piece Type</option>
                    <option cursor:pointer value="purchasable">Purchasable</option>
                    <option cursor:pointer value="price">Price</option>
                  </select>
                </span>
            <button style="margin-left: .25rem" v-on:click="reverseData()" type="button"
                    class="button button-tag is-info is-light">
              <fa icon="sort"></fa>
            </button>
          </div>
        </div>
        <div class="field" v-else>
          <label class="label has-text-white">Order by</label>
          <div class="control has-icons-left">
                
                <span class="select">
                  <select disabled>
                    <option cursor:pointer value="released">Release date</option>
                    <option cursor:pointer value="stayed">Run for</option>
                    <option cursor:pointer value="name">Name</option>
                    <option cursor:pointer value="creator">Creator</option>
                    <option cursor:pointer value="rarity">Rarity</option>
                    <option cursor:pointer value="type">Piece Type</option>
                    <option cursor:pointer value="purchasable">Purchasable</option>
                    <option cursor:pointer value="price">Price</option>
                  </select>
                </span>
            <!-- <span class="icon is-small is-left has-tooltip-arrow has-tooltip-multiline" data-tooltip="Order items by release date, Run for, Name, Price, Purchasable, Rarity, Piece Type"> -->
            <span class="icon is-small is-left has-tooltip-arrow has-tooltip-multiline"
                  data-tooltip="Please login to see that data.">
                  <i class="fas fa-lock"></i>
                </span>
            <button style="margin-left: .25rem" type="button" class="button button-tag is-info is-light">
              <fa icon="sort"></fa>
            </button>
          </div>
        </div>
      </div>

      <div class="column">
        <div v-if="isPremiumAccount" class="field">
          <label class="label has-text-white">Filter by</label>
          <div class="field-body" style="margin-bottom: 0.5rem;">
            <div class="field has-addons">
              <div class="control">
                  <span class="select is-empty">
                    <select style="margin-right: 0.25rem;" @change="onFilterChange($event)" v-model="filterSelected">
                      <option value="released">Release date</option>
                      <option value="stayed">Run for</option>
                      <option value="creator">Creator</option>
                      <option value="rarity">Rarity</option>
                      <option value="type">Piece Type</option>
                      <option value="purchasable">Purchasable</option>
                      <option value="price">Price</option>
                      <option value="tags">Tag</option>
                    </select>
                  </span>
              </div>
              <div class="control is-clearfix">
                <AutoComplete
                    :dataDropdown="filterData" v-debounce:500ms="filterHandler" @clickItem="clickedFilterHandler"
                    @uiCleared="uiCleared" @uiClosed="uiClosed" :clearUI="shouldClearUI"
                    :closeUI="shouldCloseUI" :setText="setTextAutoComplete"
                ></AutoComplete>
              </div>
              <button style="margin-left: .25rem" v-on:click="reverseData()" type="button"
                      class="button button-tag is-info is-light">
                <fa icon="sort"></fa>
              </button>
            </div>
          </div>

          <div class="field-body">
            <div class="field has-addons">
              <div class="control">
                  <span class="select is-empty">
                    <select style="margin-right: 0.25rem;" @change="onFilterChange2($event)" v-model="filterSelected2">
                      <option value="released">Release date</option>
                      <option value="stayed">Run for</option>
                      <option value="creator">Creator</option>
                      <option value="rarity">Rarity</option>
                      <option value="type">Piece Type</option>
                      <option value="purchasable">Purchasable</option>
                      <option value="price">Price</option>
                      <option value="tags">Tag</option>
                    </select>
                  </span>
              </div>
              <div class="control is-clearfix">
                <AutoComplete
                    :dataDropdown="filterData2" v-debounce:500ms="filterHandler2" @clickItem="clickedFilterHandler2"
                    @uiCleared="uiCleared2" @uiClosed="uiClosed2" :clearUI="shouldClearUI2"
                    :closeUI="shouldCloseUI2" :setText="setTextAutoComplete2"
                ></AutoComplete>
              </div>
              <button style="margin-left: .25rem" v-on:click="reverseData()" type="button"
                      class="button button-tag is-info is-light">
                <fa icon="sort"></fa>
              </button>
            </div>
          </div>    
          
        </div>
        <div class="field" v-else>
          <label class="label has-text-white">Filter by </label>
          <div class="field-body" style="margin-bottom: 0.5rem;">
            <div class="field has-addons">
              <div class="control has-icons-left">
                  <span class="select is-empty">
                    <select disabled style="margin-right: 0.25rem;">
                      <option value="released">Release date</option>
                      <option value="stayed">Run for</option>
                      <option value="creator">Creator</option>
                      <option value="rarity">Rarity</option>
                      <option value="type">Piece Type</option>
                      <option value="purchasable">Purchasable</option>
                      <option value="price">Price</option>
                      <option value="tags">Tag</option>
                    </select>
                  </span>
                <!-- <span class="icon is-small is-left has-tooltip-arrow has-tooltip-multiline" data-tooltip="Order items by release date, Run for, Name, Price, Purchasable, Rarity, Piece Type"> -->
                <span class="icon is-small is-left has-tooltip-arrow has-tooltip-multiline"
                      data-tooltip="Please login to see that data.">
                    <i class="fas fa-lock"></i>
                  </span>
              </div>
              <div class="control is-clearfix">
                <AutoComplete
                    :dataDropdown="filterData" @uiCleared="uiCleared"
                    @uiClosed="uiClosed" :clearUI="shouldClearUI"
                    :closeUI="shouldCloseUI" :setText="setTextAutoComplete"
                ></AutoComplete>
              </div>
              <button style="margin-left: .25rem" id="fa" type="button" class="button button-tag is-info is-light">
                <fa icon="sort"></fa>
              </button>


            </div>
          </div>

          <div class="field-body">
            <div class="field has-addons">
              <div class="control has-icons-left">
                  <span class="select is-empty">
                    <select disabled style="margin-right: 0.25rem;">
                      <option value="released">Release date</option>
                      <option value="stayed">Run for</option>
                      <option value="creator">Creator</option>
                      <option value="rarity">Rarity</option>
                      <option value="type">Piece Type</option>
                      <option value="purchasable">Purchasable</option>
                      <option value="price">Price</option>
                      <option value="tags">Tag</option>
                    </select>
                  </span>
                <!-- <span class="icon is-small is-left has-tooltip-arrow has-tooltip-multiline" data-tooltip="Order items by release date, Run for, Name, Price, Purchasable, Rarity, Piece Type"> -->
                <span class="icon is-small is-left has-tooltip-arrow has-tooltip-multiline"
                      data-tooltip="Please login to see that data.">
                    <i class="fas fa-lock"></i>
                  </span>
              </div>
              <div class="control is-clearfix">
                <AutoComplete
                    :dataDropdown="filterData2" @uiCleared="uiCleared2"
                    @uiClosed="uiClosed2" :clearUI="shouldClearUI2"
                    :closeUI="shouldCloseUI2" :setText="setTextAutoComplete2"
                ></AutoComplete>
              </div>
              <button style="margin-left: .25rem" id="fa" type="button" class="button button-tag is-info is-light">
                <fa icon="sort"></fa>
              </button>


            </div>
          </div>
          
        </div>
      </div>

      <div class="column is-full has-text-white" id="results">
        <p>{{ numberOfResults }} pieces found.</p>
        <label class="checkbox">
          <input type="checkbox" v-model="MinecraftBoxChecked" @change="updatePage()">
          Exclude Minecraft Content
        </label>
        <br>
        <label v-if="isPremiumAccount" class="checkbox">
          <input type="checkbox" v-model="FirstPartyBoxChecked" @change="updatePage()">
          Exclude First Party Content
        </label>
        <br>
        <label v-if="isPremiumAccount" class="checkbox">
          <input type="checkbox" v-model="EmotesBoxChecked" @change="updatePage()">
          Exclude Emotes Content
        </label>
      </div>

      <div class="column is-one-fifth" v-for="data in jsonToRender" :key="data">
        <PersonaItemCard :pieceOfContent="data" :isPremium=isPremiumAccount :clickable="true"
                         @setFilter="setFilterFromClick"/>
      </div>
    </div>

    <nav class="pagination is-centered has-text-white" role="navigation" aria-label="pagination">
      <a class="pagination-previous has-text-white" v-on:click="changePage(page-1)">Previous</a>
      <a class="pagination-next has-text-white" v-on:click="changePage(page+1)">Next page</a>
      <ul class="pagination-list has-text-white">
        <li><a class="pagination-link has-text-white" aria-label="Goto page 1"
               v-on:click="changePage(1)">1</a></li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>

        <li><a class="pagination-link is-current has-text-white"
               :aria-label="'Goto page ' + (page)" aria-current="page">{{ page }}</a></li>

        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li><a class="pagination-link has-text-white"
               :aria-label="'Goto page ' + (totalPageNumber)"
               v-on:click="changePage(totalPageNumber)">{{ totalPageNumber }}</a></li>
      </ul>
    </nav>

  </div>
  <br>
</template>

<script>
import PersonaItemCard from '../components/PersonaItemCard.vue'
import AutoComplete from '../components/AutoComplete.vue'
import NProgress from "nprogress";
import {useMeta} from "vue-meta";

export default {
  name: 'Products',
  props: {
    isPremiumAccount: Boolean,
    jsonData: Object
  },
  setup () {
    useMeta({
      title: 'Products' ,
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  data() {
    return {
      jsonToRender: Object.values(this.jsonData["Pieces"]).slice(0, 25),
      page: this.$route.params.pageNumber || 1,
      totalPageNumber: Math.ceil(Object.values(this.jsonData["Pieces"]).length / 25),
      numberOfResults: Object.values(this.jsonData["Pieces"]).length,
      orderBySelected: 'released',
      filterSelected: 'released',
      filterSelected2: 'released',
      filterData: [],
      filterData2: [],
      shouldClearUI: false,
      shouldClearUI2: false,
      shouldCloseUI: false,
      shouldCloseUI2: false,
      setTextAutoComplete: "",
      setTextAutoComplete2: "",
      searchTerm: "",
      MinecraftBoxChecked: false,
      FirstPartyBoxChecked: false,
      EmotesBoxChecked: false,
      stayedForList: [],
      lastSearchTerm: "",
      lastSearchJson: Object.values(this.jsonData["Pieces"]),
      lastFilterValue: "",
      lastFilterValue2: "",
      lastFilterData: [],
      lastFilterData2: [],
      monthsList: [],
      creatorList: [],
      priceList: [],
      typesList: [],
      tagsList: []
    }
  },
  components: {
    PersonaItemCard,
    AutoComplete
  },
  created() {
    for (let i in this.jsonData["Pieces"]) {
      if (!this.monthsList.includes(this.jsonData["Pieces"][i]["monthCreated"]) && typeof this.jsonData["Pieces"][i]["monthCreated"] != "undefined") {
        this.monthsList.push(this.jsonData["Pieces"][i]["monthCreated"])
      }
      if (!this.creatorList.includes(this.jsonData["Pieces"][i]["displayProperties"]["creatorName"]) && typeof this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] != "undefined") {
        this.creatorList.push(this.jsonData["Pieces"][i]["displayProperties"]["creatorName"])
      }
      if (!this.priceList.includes(String(this.jsonData["Pieces"][i]["displayProperties"].price)) && typeof this.jsonData["Pieces"][i]["displayProperties"].price != "undefined") {
        this.priceList.push(String(this.jsonData["Pieces"][i]["displayProperties"].price))
      }
      if (!this.stayedForList.includes((this.jsonData["Pieces"][i].stayedFor + "")) && typeof this.jsonData["Pieces"][i].stayedFor !== 'undefined') {
        this.stayedForList.push((this.jsonData["Pieces"][i].stayedFor + ""))
      }
      if (!this.typesList.includes(this.jsonData["Pieces"][i]["displayProperties"]["pieceType"]) && typeof this.jsonData["Pieces"][i]["displayProperties"]["pieceType"] != "undefined") {
        this.typesList.push(this.jsonData["Pieces"][i]["displayProperties"]["pieceType"])
      }
      for (let i2 in this.jsonData["Pieces"][i].tags) {
        if (!this.tagsList.includes(this.jsonData["Pieces"][i].tags[i2]) && typeof this.jsonData["Pieces"][i].tags[i2] != "undefined") {
          this.tagsList.push(this.jsonData["Pieces"][i].tags[i2])
        }
      }
      if (typeof this.jsonData["Pieces"][i]["displayProperties"]["creatorName"] == "undefined") {
        delete this.jsonData["Pieces"][i];
      }
    }
    this.tagsList.push("featured+", "featured", "removed", "extended", "first_party", "delisted", "relisted", "m_tag", "m2_tag", "x_tag")
    this.priceList = this.priceList.sort(function (a, b) {
      return a - b;
    });
    this.creatorList = this.creatorList.sort((a, b) => a.localeCompare(b))
    this.filterData = this.monthsList;
    this.search()
  },
  watch: {
    isPremiumAccount: function (newVal) {
      if (newVal) {
        for (let i in this.jsonData["Pieces"]) {
          if (!this.stayedForList.includes(this.jsonData["Pieces"][i].stayedFor)
              && typeof this.jsonData["Pieces"][i].stayedFor !== 'undefined') {
            this.stayedForList.push(this.jsonData["Pieces"][i].stayedFor)
          }
        }
      }
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push({path: "/" + path})
    },
    changePage(receivedPage) {
      if (receivedPage > 0 && receivedPage <= this.totalPageNumber) {
        this.page = receivedPage

        this.$router.push({path: '/products/' + receivedPage, query: {search: this.searchTerm,
            filterBy: this.filterSelected, filterBy2: this.filterSelected2, orderBy: this.orderBySelected, filterValue: this.lastFilterValue, filterValue2: this.lastFilterValue2}})

        this.$route.query.search = this.searchTerm
        this.$route.query.orderBy = this.orderBySelected
        this.$route.query.filterBy = this.filterSelected
        this.$route.query.filterBy2 = this.filterSelected2
        this.$route.query.filterValue = this.lastFilterValue
        this.$route.query.filterValue2 = this.lastFilterValue2

        if (Array.isArray(this.$route.params.pathMatch)) {
          this.$route.params.pathMatch[1] = receivedPage;
        }

        this.jsonToRender = Object.values(this.lastSearchJson).slice((this.page - 1) * 25, (this.page) * 25)
      }
    },
    search() {
      let shouldRefresh = false

      if (this.$route.query.search) {
        if (this.$route.query.search !== this.searchTerm) {
          if (this.searchTerm === "" && this.lastSearchTerm === "") {
            shouldRefresh = true
            this.lastSearchTerm = this.$route.query.search
            this.searchTerm = this.$route.query.search
          }
        }
      }

      if (this.$route.query.orderBy) {
        if (this.$route.query.orderBy !== this.orderBySelected) {
          if (this.orderBySelected === 'released') {
            shouldRefresh = true
            this.orderBySelected = this.$route.query.orderBy
          }
        }
      }

      if (this.$route.query.filterBy) {
        if (this.$route.query.filterBy !== this.filterSelected) {
          if (this.filterSelected === 'released') {
            shouldRefresh = true
            this.filterSelected = this.$route.query.filterBy
            this.onFilterChange(false)
          }
        }
      }

      if (this.$route.query.filterBy2) {
        if (this.$route.query.filterBy2 !== this.filterSelected2) {
          if (this.filterSelected2 === 'released') {
            shouldRefresh = true
            this.filterSelected2 = this.$route.query.filterBy2
            this.onFilterChange2(false)
          }
        }
      }

      if (this.$route.query.filterValue) {
        if (this.$route.query.filterValue !== this.lastFilterValue) {
          if (this.lastFilterValue === "") {
            shouldRefresh = true
            this.filterHandlerFromSearch(this.$route.query.filterValue)
          }
        }
      }

      if (this.$route.query.filterValue2) {
        if (this.$route.query.filterValue2 !== this.lastFilterValue2) {
          if (this.lastFilterValue2 === "") {
            shouldRefresh = true
            this.filterHandlerFromSearch2(this.$route.query.filterValue2)
          }
        }
      }

      if (this.$route.params.pathMatch[0]) {
        if (this.$route.params.pathMatch[0] !== this.page) {
          this.changePage(parseInt(this.$route.params.pathMatch[0]))
        }
      }

      if (shouldRefresh) {
        this.updatePage()
      }
    },
    reverseData() {
      this.changePage(1)

      this.lastSearchJson = this.lastSearchJson.reverse()
      this.jsonToRender = this.lastSearchJson.slice(0, 25)
    },
    searchHandler(value) {
      this.lastSearchTerm = value
      this.changePage(1)
      this.updatePage()
    },
    filterHandlerFromSearch(data) {
      if (this.filterData.includes(data)) {
        this.lastFilterValue = data
        this.setTextAutoComplete = data;
      }
    },
    filterHandlerFromSearch2(data) {
      if (this.filterData2.includes(data)) {
        this.lastFilterValue2 = data
        this.setTextAutoComplete2 = data;
      }
    },
    filterHandler(data) {
      if (this.filterData.includes(data)) {
        this.clickedFilterHandler(data)
        this.closeUI()
      } else {
        if (data === "" && this.lastFilterValue !== "") {
          this.clickedFilterHandler(data)
        }
        this.filterData = this.lastFilterData.filter(element => element.toString().toLowerCase().includes(data.toLowerCase()))
      }
    },
    filterHandler2(data) {
      if (this.filterData2.includes(data)) {
        this.clickedFilterHandler2(data)
        this.closeUI2()
      } else {
        if (data === "" && this.lastFilterValue2 !== "") {
          this.clickedFilterHandler2(data)
        }
        this.filterData2 = this.lastFilterData2.filter(element => element.toString().toLowerCase().includes(data.toLowerCase()))
      }
    },
    clickedFilterHandler: function (data) {
      this.lastFilterValue = data
      this.changePage(1)
      this.updatePage()
    },
    clickedFilterHandler2: function (data) {
      this.lastFilterValue2 = data
      this.changePage(1)
      this.updatePage()
    },
    onOrderByChange() {
      this.changePage(1)
      this.updatePage()
    },
    uiCleared() {
      this.shouldClearUI = false
    },
    uiCleared2() {
      this.shouldClearUI2 = false
    },
    uiClosed() {
      this.shouldCloseUI = false
    },
    uiClosed2() {
      this.shouldCloseUI2 = false
    },
    clearUI() {
      this.shouldClearUI = true
    },
    closeUI() {
      this.shouldCloseUI = true
    },
    clearUI2() {
      this.shouldClearUI2 = true
    },
    closeUI2() {
      this.shouldCloseUI2 = true
    },
    onFilterChange(shouldClear = true) {
      this.$route.query.filterBy = this.filterSelected
      this.lastFilterValue = ""
      if (shouldClear) {
        this.clearUI()
        this.updatePage()
      }
      switch (this.filterSelected) {
        case 'rarity': {
          this.filterData = ["Legendary", "Epic", "Rare", "Uncommon", "Common"]
          break;
        }
        case 'creator': {
          this.filterData = this.creatorList
          break;
        }
        case 'purchasable': {
          this.filterData = ["true", "false"]
          break;
        }
        case 'price': {
          this.filterData = this.priceList
          break;
        }
        case 'stayed': {
          this.filterData = this.stayedForList
          break;
        }
        case 'released': {
          this.filterData = this.monthsList
          break;
        }
        case 'type': {
          this.filterData = this.typesList
          break;
        }
        case 'tags': {
          this.filterData = this.tagsList
          break;
        }
      }
      this.lastFilterData = this.filterData
    },
    onFilterChange2(shouldClear = true) {
      this.$route.query.filterBy2 = this.filterSelected2
      this.lastFilterValue2 = ""
      if (shouldClear) {
        this.clearUI2()
        this.updatePage()
      }
      switch (this.filterSelected2) {
        case 'rarity': {
          this.filterData2 = ["Legendary", "Epic", "Rare", "Uncommon", "Common"]
          break;
        }
        case 'creator': {
          this.filterData2 = this.creatorList
          break;
        }
        case 'purchasable': {
          this.filterData2 = ["true", "false"]
          break;
        }
        case 'price': {
          this.filterData2 = this.priceList
          break;
        }
        case 'stayed': {
          this.filterData2 = this.stayedForList
          break;
        }
        case 'released': {
          this.filterData2 = this.monthsList
          break;
        }
        case 'type': {
          this.filterData2 = this.typesList
          break;
        }
        case 'tags': {
          this.filterData2 = this.tagsList
          break;
        }
      }
      this.lastFilterData2 = this.filterData2
    },
    setFilterFromClick(data) {
      if (this.isPremiumAccount) {
        this.filterSelected = data[0]
        this.onFilterChange(false)
        this.clickedFilterHandler(data[1])
        this.setTextAutoComplete = data[1].toString()
      }
    },
    setFilterFromClick2(data) {
      if (this.isPremiumAccount) {
        this.filterSelected2 = data[0]
        this.onFilterChange2(false)
        this.clickedFilterHandler2(data[1])
        this.setTextAutoComplete2 = data[1].toString()
      }
    },
    updatePage() {
      NProgress.start();
      let filteredJson = Object.values(this.jsonData["Pieces"])

      if (this.searchTerm !== "") {
        filteredJson = filteredJson.filter(element =>
            element.title["neutral"].toLowerCase().includes(this.searchTerm.toLowerCase()))
      }

      switch (this.orderBySelected) {
        case 'name': {
          filteredJson = filteredJson.sort(function (a, b) {
            return (a.title["neutral"].toUpperCase()
                < b.title["neutral"].toUpperCase()) ? -1 :
                (a.title["neutral"].toUpperCase() > b.title["neutral"].toUpperCase()) ? 1 : 0;
          });
          break;
        }
        case 'rarity': {
          filteredJson = filteredJson.sort(function (a, b) {
            return (a["displayProperties"]["rarity"].toUpperCase() <
                b["displayProperties"]["rarity"].toUpperCase()) ? -1 :
                (a["displayProperties"]["rarity"].toUpperCase() > b["displayProperties"]["rarity"].toUpperCase()) ? 1 : 0;
          });
          break;
        }
        case 'creator': {
          filteredJson = filteredJson.sort(function (a, b) {
            return (a["displayProperties"]["creatorName"].toUpperCase() <
                b["displayProperties"]["creatorName"].toUpperCase()) ? -1 :
                (a["displayProperties"]["creatorName"].toUpperCase() >
                    b["displayProperties"]["creatorName"].toUpperCase()) ? 1 : 0;
          });
          break;
        }
        case 'purchasable': {
          filteredJson = filteredJson.sort(function (a, b) {
            return (a["displayProperties"]["purchasable"] && !b["displayProperties"]["purchasable"]) ?
                -1 : (!a["displayProperties"]["purchasable"] && b["displayProperties"]["purchasable"]) ? 1 : 0;
          });
          break;
        }
        case 'price': {
          filteredJson = filteredJson.sort(function (a, b) {
            return (a["displayProperties"].price > b["displayProperties"].price) ? -1 :
                (a["displayProperties"].price < b["displayProperties"].price) ? 1 : 0;
          });
          break;
        }
        case 'stayed': {
          filteredJson = filteredJson.sort(function (a, b) {
            return (a.stayedFor > b.stayedFor) ? -1 : (a.stayedFor < b.stayedFor) ? 1 : 0;
          });
          break;
        }
        case 'released': {
          filteredJson = filteredJson.sort(function (a, b) {
            return (new Date(a["creationDate"]) > new Date(b["creationDate"])) ? -1 :
                (new Date(a["creationDate"]) < new Date(b["creationDate"])) ? 1 : 0;
          });
          break;
        }
        case 'type': {
          filteredJson = filteredJson.sort(function (a, b) {
            return (a["displayProperties"]["pieceType"].toUpperCase() <
                b["displayProperties"]["pieceType"].toUpperCase()) ? -1 :
                (a["displayProperties"]["pieceType"].toUpperCase() >
                    b["displayProperties"]["pieceType"].toUpperCase()) ? 1 : 0;
          });
          break;
        }
      }


      if (this.lastFilterValue !== "") {
        switch (this.filterSelected) {
          case 'rarity': {
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"]["rarity"] === this.lastFilterValue)
            break;
          }
          case 'creator': {
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"]["creatorName"] === this.lastFilterValue)
            break;
          }
          case 'purchasable': {
            if (typeof this.lastFilterValue != "boolean") {
              this.lastFilterValue = (this.lastFilterValue === "true");
            }
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"]["purchasable"] === this.lastFilterValue)
            break;
          }
          case 'price': {
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"].price === Number(this.lastFilterValue))
            break;
          }
          case 'stayed': {
            filteredJson = filteredJson.filter(element =>
                element.stayedFor === Number(this.lastFilterValue))
            break;
          }
          case 'released': {
            filteredJson = filteredJson.filter(element =>
                element["monthCreated"] === this.lastFilterValue)
            break;
          }
          case 'type': {
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"]["pieceType"] === this.lastFilterValue)
            break;
          }
          case 'tags': {
            if (this.lastFilterValue === "featured" || this.lastFilterValue === "featured+" ||
                this.lastFilterValue === "removed" || this.lastFilterValue === "extended"
                || this.lastFilterValue === "first_party" || this.lastFilterValue === "delisted" 
                || this.lastFilterValue === "relisted" || this.lastFilterValue === "m_tag" 
                || this.lastFilterValue === "m2_tag" || this.lastFilterValue === "x_tag") {
              filteredJson = filteredJson.filter(element => element.extraTags.includes(this.lastFilterValue))
            } else {
              filteredJson = filteredJson.filter(element => element.tags.includes(this.lastFilterValue))
            }
            break;
          }
        }
      }

      if (this.lastFilterValue2 !== "") {
        switch (this.filterSelected2) {
          case 'rarity': {
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"]["rarity"] === this.lastFilterValue2)
            break;
          }
          case 'creator': {
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"]["creatorName"] === this.lastFilterValue2)
            break;
          }
          case 'purchasable': {
            if (typeof this.lastFilterValue2 != "boolean") {
              this.lastFilterValue2 = (this.lastFilterValue2 === "true");
            }
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"]["purchasable"] === this.lastFilterValue2)
            break;
          }
          case 'price': {
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"].price === Number(this.lastFilterValue2))
            break;
          }
          case 'stayed': {
            filteredJson = filteredJson.filter(element =>
                element.stayedFor === Number(this.lastFilterValue2))
            break;
          }
          case 'released': {
            filteredJson = filteredJson.filter(element =>
                element["monthCreated"] === this.lastFilterValue2)
            break;
          }
          case 'type': {
            filteredJson = filteredJson.filter(element =>
                element["displayProperties"]["pieceType"] === this.lastFilterValue2)
            break;
          }
          case 'tags': {
            if (this.lastFilterValue2 === "featured" || this.lastFilterValue2 === "featured+" ||
                this.lastFilterValue2 === "removed" || this.lastFilterValue2 === "extended"
                || this.lastFilterValue2 === "first_party" || this.lastFilterValue2 === "delisted" 
                || this.lastFilterValue2 === "relisted" || this.lastFilterValue2 === "m_tag" 
                || this.lastFilterValue2 === "m2_tag" || this.lastFilterValue === "x_tag") {
              filteredJson = filteredJson.filter(element => element.extraTags.includes(this.lastFilterValue2))
            } else {
              filteredJson = filteredJson.filter(element => element.tags.includes(this.lastFilterValue2))
            }
            break;
          }
        }
      }

      if (this.MinecraftBoxChecked) {
        filteredJson = filteredJson.filter(element => element["displayProperties"]["creatorName"] !== "Minecraft")
      }

      if (this.FirstPartyBoxChecked) {
        filteredJson = filteredJson.filter(element => !element.extraTags.includes("first_party"))
      }

      if (this.EmotesBoxChecked) {
        filteredJson = filteredJson.filter(element => element["displayProperties"]["pieceType"] !== "persona_emote")
      }

      if (filteredJson.length === 0) {
        this.numberOfResults = 0
        this.totalPageNumber = 1
        this.jsonToRender = [];
        this.lastSearchJson = []
      } else {
        this.numberOfResults = filteredJson.length
        this.jsonToRender = filteredJson.slice(25 * (this.page - 1), 25 * this.page);
        this.totalPageNumber = Math.ceil(filteredJson.length / 25)
        this.lastSearchJson = filteredJson
      }
      NProgress.done();
    }
  }
}
</script>

<style>
#fa {
  background-color: white;
  color: #1C1E24;
}

strong {
  color: #888e98 !important;
}

input[type="text"], input[type="search"] {
  background-color: #374151 !important;
  border: 0 !important;
}

.field {
  text-align: left;
}

#results {
  text-align: left !important;
}

.label {
  text-align: left;
}

.select {
  -webkit-box-align: center;
  -webkit-box-pack: start;
}
</style>
